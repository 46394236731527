import React, { Component } from 'react';
import $ from 'jquery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

export default class FieldQ12 extends Component {

	constructor(props) {
    super(props);
        this.state = { itemArray:[],ItemsDes: new Map(),newItemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        let name = event.target.name;
		let fieldId = this.props.data.fieldId;
        let value = event.target.value;
		let itemArray = this.state.itemArray
		let newItemArray = this.state.newItemArray
		let ItemsDes = this.state.ItemsDes
		
		let atr = event.target.getAttribute('data_atr');
		let attr = event.target.getAttribute('data_attr');
		
		if(atr){
			$('#'+atr).css('display','block');
        }else{
			$('#'+attr).css('display','none');
        }
		let itemString = '';
		if(atr != 2){
			itemArray[name] = name+':'+value;
			let newItemArray = []
			Object.keys(itemArray).map(function(key) {
			   newItemArray.push(itemArray[key]);
			});
			
			this.setState({itemArray,newItemArray});
			itemString = newItemArray.toString();
			this.props.func(fieldId,itemString);
			
		}else{
			
			if(this.state.newItemArray.indexOf(name) !==-1){
				ItemsDes.set(name, value)
			}
			let that = this;
			setTimeout(function(){
				let itemArray2 = [];
				that.state.newItemArray.map((itm, i) => {
					let des = '';
					if(ItemsDes.get(itm) && ItemsDes.get(itm) != undefined){
						des = ItemsDes.get(itm);
					}
					if(des){
						itemArray2[i] = itm+':'+des;
					}else{
						itemArray2[i] = itm;
					}
					
				});
				
				itemString = itemArray2.toString();
				that.props.func(fieldId,itemString);
			}, 1000);
		}
    }
	
    render(){
        const {data} = this.props;
        let id = data.fieldId;
		let options = data.options.split(',');
		let buttonLabel = data.buttonLabel.split(',');
		
		let kioskResponse = data.kioskResponse;
		
        let response = '';
        if(kioskResponse.size > 0){
            let resAns = kioskResponse.get(id);
            if(resAns !== undefined){
                response = resAns.split(',');
            }
        }
		
		let optionsHtml = '';
		if(options){
			optionsHtml = options.map(function(rowText,i) {
				let r = i+1;
				let name = '';
				let res = '';
				let des = '';
				
				if(response.length > 0){
					response.map((str, j) => {
						let n = str.split(':');
						if(n[0] == rowText){
							name = n[0];
							res = n[1];
							des = n[2];
						}
					})
				} 
				if(name == rowText){
					return (	
						<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" width='220px'>
							{rowText}
							</TableCell>
							<TableCell align="center">
								<input data_attr={id+'-'+i+'-text'} checked={res == buttonLabel[0] ? true:false } className="cus-radio-btn" type="radio" value={buttonLabel[0]}  name={rowText}/> {buttonLabel[0]}
							</TableCell>
							<TableCell align="center">
								<input data_atr={id+'-'+i+'-text'} checked={res == buttonLabel[1] ? true:false } className="cus-radio-btn" type="radio" value={buttonLabel[1]}  name={rowText}/> {buttonLabel[1]}
							</TableCell>
							<TableCell align="center" width='300px'>
							{des || res == buttonLabel[1] ?
								<div id={id+'-'+i+'-text'}>
								{buttonLabel[2]} <input data_atr={2} text="type" name={rowText+':'+buttonLabel[1]} className="form-control" value={des}/>
								</div>
							:null}
							</TableCell>
						</TableRow>
					);
				}else{
					
					return (	
						<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" width='220px'>
							{rowText}
							</TableCell>
							<TableCell align="center">
								<input data_attr={id+'-'+i+'-text'} className="cus-radio-btn" type="radio" value={buttonLabel[0]}  name={rowText}/> {buttonLabel[0]}
							</TableCell>
							<TableCell align="center">
								<input data_atr={id+'-'+i+'-text'} className="cus-radio-btn" type="radio" value={buttonLabel[1]}  name={rowText}/> {buttonLabel[1]}
							</TableCell>
							<TableCell align="center" width='300px'>
								<div id={id+'-'+i+'-text'} style={{display:'none'}} >
								{buttonLabel[2]} <input data_atr={2} text="type" name={rowText+':'+buttonLabel[1]} className="form-control" />
								</div>
							</TableCell>
						</TableRow>
					);
				}
			}) 
		}
		
		return (
			<TableContainer component={Paper} onChange={this.shoot}>
			  <Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
				<TableBody>
					{optionsHtml}
				</TableBody>
			  </Table>
			</TableContainer>
		);
    }
	
}


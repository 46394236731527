import React from 'react'; 
import $ from 'jquery';

export default function FieldAssessment(props) {
	
	const fieldId = props.fieldId;
	const question = props.question;
	const required = props.required;
	const handleAddTag=(type)=>{
		if(type == 'auto'){
			let tag = $('#autoTag').find(":selected").text();
			let boxHtml = $('#tempHtml_'+fieldId).val();
			$('#tempHtml_'+fieldId).val(boxHtml +' '+tag);
		}else if(type == 'required'){
			let tag = $('#rqTag').find(":selected").text();
			let boxHtml = $('#tempHtml_'+fieldId).val();
			$('#tempHtml_'+fieldId).val(boxHtml +' '+tag);
		}else if(type == 'non-required'){
			let tag = $('#nrqTag').find(":selected").text();
			let boxHtml = $('#tempHtml_'+fieldId).val();
			$('#tempHtml_'+fieldId).val(boxHtml +' '+tag);
		}
	}
	
	const handleAddData=()=>{
        $('#tempHtml_'+fieldId).val('<<last name>> <<full name>> <<first name>> <<sex>> <<br>> <<Employment Details>>>');
    }
	
	return (<div className="subjective">
		<div className="row">
			<div className={'col-md-10 input-text'}>
				<h5>Assessment</h5>
				{ question } {required == 'Y' ? <span className="required">*</span> : ''}
			</div>
			<div className={'col-md-2'}>
				{props.NoteHTML} {props.NoteModal}
			</div>
		</div>
		<div className="row">
			<div className={"col-md-12 answer"} >
				<div className="row">
					<div className="col-md-9">
						<select className="form-control">
							<option value=""> Please Select</option> 
							<option value="3">template17april</option>
							<option value="4">template17may</option>
							<option value="5">9 june</option>
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" data-target={"#subtemp_"+fieldId} data-toggle="modal" >Preview</button>
                    </div>
                </div>
				<div className="row">
					<div className="col-md-5">
						<select id="autoTag" className="form-control">
							<option value=""> Please Select</option> 
							<option value="0">&lt;&lt;last name&gt;&gt;</option>
						    <option value="1">&lt;&lt;full name&gt;&gt;</option>
						    <option value="2">&lt;&lt;first name&gt;&gt;</option>
						    <option value="3">&lt;&lt;first/last name&gt;&gt;</option>
						    <option value="4">&lt;&lt;sex&gt;&gt;</option>
						    <option value="5">&lt;&lt;age&gt;&gt;</option>
						    <option value="6">&lt;&lt;dob&gt;&gt;</option>
						    <option value="7">&lt;&lt;height&gt;&gt;</option>
						    <option value="8">&lt;&lt;weight&gt;&gt;</option>
						    <option value="9">&lt;&lt;race&gt;&gt;</option>							
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" onClick = {()=>handleAddTag('auto')}>Add Auto Tag</button>
                    </div>
                </div>
				
				<div className="row">
					<div className="col-md-5">
						<select id="rqTag" className="form-control">
							<option value=""> Please Select</option> 
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" onClick={()=>handleAddTag('required')}>Required Field Tags</button>
                    </div>
                </div>
				
				<div className="row">
					<div className="col-md-5">
						<select id="nrqTag" className="form-control">
							<option value=""> Please Select</option>
							<option value="Note for Q1">Note for Q1</option>
							<option value="Note for Q2">Note for Q2</option>
							<option value="Note for Q3">Note for Q3</option>
							<option value="Note for Q4">Note for Q4</option>
							<option value="Note for Q5">Note for Q5</option>
							<option value="Note for Q6">Note for Q6</option>							
						</select>
					</div>
					<div className="col-md-3">
                        <button className="btn btn-primary" onClick={()=>handleAddTag('non-required')}>Non-Required Tags</button>
                    </div>
                </div>
				
				<div className="row">
					<div className="col-md-10">
						<label>Enter text blow and Insert "Tag" by choosing from above drop down and click Add tag</label>
                        <textarea id={"tempHtml_"+fieldId}className="form-control" rows="10"></textarea>
                    </div>
					
					<div className="col-md-5">
						<br />
                        <button className="btn btn-primary" data-target={"#tagTras_"+fieldId} data-toggle="modal">Traslate Tags</button>&nbsp;&nbsp;
                        <button className="btn btn-primary" data-target={"#tagPreview_"+fieldId} data-toggle="modal">Preview</button>
                    </div>
                </div>
				
				<div className="row">
					<div className="col-md-9">
						<label>Enter name of template to save</label>
						<input type="text" className={"form-control temp-name-"+fieldId} name="templateName"/>
                    </div>
					<div className="col-md-3 mt-4">
                        <button className="btn btn-primary">Save / add to list</button>
                    </div>
                </div>
				
			</div>   
		</div>
		<div className="row">
			<div className="modal" id={"subtemp_"+fieldId}>
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-body">
						&lt;&lt;last name&gt;&gt; &lt;&lt;full name&gt;&gt; &lt;&lt;first name&gt;&gt; &lt;&lt;sex&gt;&gt; &lt;&lt;br&gt;&gt; &lt;&lt;Employment Details&gt;&gt;
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button><button type="button" className="btn btn-primary" data-dismiss="modal" onClick = {()=>handleAddData()}>Accept</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div className="row">
			<div className="modal" id={"tagTras_"+fieldId}>
				<div className="modal-dialog modal-lg" style={{width:'360px'}}>
					<div className="modal-content">
						<div className="modal-body">
							<h5>Please select the Patient.</h5>
							<select className="form-control">
								<option value=""> Please Select</option> 
								<option value="Patient 1">Patient 1</option>
								<option value="Patient 2">Patient 2</option>				
							</select>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button><button type="button" className="btn btn-primary" data-dismiss="modal">Traslate</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		
		<div className="row">
			<div className="modal" id={"tagPreview_"+fieldId}>
				<div className="modal-dialog modal-lg" style={{width:'660px'}}>
					<div className="modal-content">
						<div id={"tagPreHtml_"+fieldId} className="modal-body">
							<span class='auto-tag'>&lt;&lt;last name&gt;&gt; &lt;&lt;full name&gt;&gt; &lt;&lt;first name&gt;&gt; &lt;&lt;sex&gt;&gt; &lt;&lt;br&gt;&gt; &lt;&lt;Employment Details&gt;&gt;</span>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>);
}

import React, { Component } from 'react'; 
import $ from 'jquery';
import {DebounceInput} from 'react-debounce-input';

export default class Q10 extends Component {
	constructor(props) {
        super(props);
        this.state={
            description:'',
        }
        this.shoot = this.shoot.bind(this); 
    }
    
    shoot(event) {
        this.setState({description:event.target.value})
        this.props.func(this.props.data.fieldId,event.target.value);
    }
	
    render(){
        const {data} = this.props;
        let id = data.fieldId;
		let kioskResponse = data.kioskResponse;
		//console.log('kioskResponse->',kioskResponse);
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                var ts = resAns;
            }
        }
		
        return (
            <div id={"q10"+id} style={{display: ts ? 'block':'none'}}>
                 Description : <DebounceInput element="textarea" debounceTimeout={4000} rows="2" className={'form-control res_'+id } value={ts ? ts : this.state.description} onChange={this.shoot}/>
            </div>
        );
    }
}



import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

export function WeightedCalTable(rowData) {
	
	const Item = styled(Paper)(({ theme }) => ({
	  ...theme.typography.body2,
	  padding: theme.spacing(0.5),
	  textAlign: 'left',
	  color: theme.palette.text.secondary,
	}));
	let id = rowData.fieldId;
	let options = rowData.labels;
	
	let numberColumn = rowData.numberColumn ? rowData.numberColumn : 2;
	let col = 6;
	if(numberColumn == 3){
		col = 4
	}else if(numberColumn == 4){
		col = 3
	}
	let opt = [];
	if(options){    
		opt = options.split(',');
		let order = rowData.arraignment;
		if(order == 'alphabetical'){
			opt = opt.sort();
		}else if(order == 'random'){
			opt = opt.reverse();
		}
	}
	let optionsHtml = '';
	if(opt){
		optionsHtml = opt.map(function(val,i) {
			var name = 'action'+i;
			val = val.split(':');
			return (
				<Grid item xs={1} sm={col} md={col} key={i}>
					<Item>
						<Checkbox value={val[1]} name={name} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} /> {val[0]}
					</Item>
				</Grid>
			);
		})
	}
	return (
		<Box sx={{ flexGrow: 1 }}>
			<Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
			{optionsHtml}
			</Grid>
		</Box>
	 
	);
}

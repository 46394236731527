import React from 'react'; 
import $ from 'jquery';
import config from "../config";
import guid from "./GUID";
import { gapi } from 'gapi-script';
import axios,{post,get} from 'axios';
import Checkbox from '@mui/material/Checkbox';
/* import { FieldQ1 } from "../component/Fields/Q1";
import { FieldQ2 } from "../component/Fields/Q2";
import { FieldQ3 } from "../component/Fields/Q3";
import { FieldQ4 } from "../component/Fields/Q4";
import { FieldQ5 } from "../component/Fields/Q5";
import { FieldQ6 } from "../component/Fields/Q6";
import { FieldQ7 } from "../component/Fields/Q7";
import { FieldQ8 } from "../component/Fields/Q8";
import { FieldQ9 } from "../component/Fields/Q9";
import { FieldQ10 } from "../component/Fields/Q10";
import { FieldQ11 } from "../component/Fields/Q11";
import { FieldQ11b } from "../component/Fields/Q11b"; */
//import { FieldQ12 } from "../component/Fields/Q12";
//import FieldQ13 from "../component/Fields/Q13.js";
//import { FieldSmart } from "../component/Fields/Smart";
import { FieldLink } from "../component/Fields/Link";

//import { SliderHtml } from "../component/Fields/Slider";

//import { LikertTable } from "../component/Fields/Likert";
import { BasicGridTable } from "../component/Fields/BasicGrid";
import { BasicGrid2Table } from "../component/Fields/BasicGrid2";
import { Grid5PointTable } from "../component/Fields/Grid5Point";
import { GridCheckTable } from "../component/Fields/GridCheck";
import { WeightedCalTable } from "../component/Fields/WeightedCalculation";
import { GridDropdownTable } from "../component/Fields/GridDropdown";
import { GridMultiCheckTable } from "../component/Fields/GridMultiCheck";
import { GridSingleCheckTable } from "../component/Fields/GridSingleCheck";
//import { ReductionTable } from "../component/Fields/ReductionValues";
import Launchpad from "../component/Fields/Launchpad";
import FieldVideo from "../component/Fields/VideoUpload.js";
import FieldFile from "../component/Fields/FileUpload.js";

import FieldInlineImage from "../component/Fields/InlineImage.js";
import FieldInlineVideo from "../component/Fields/InlineVideo.js";
import FieldPdfApi from "../component/Fields/PdfApi.js";
import ApiFieldVideo from "../component/Fields/ApiVideoUpload.js";

import FieldAssessment from "../component/Fields/Assessment.js";
import FieldSubjective from "../component/Fields/Subjective.js";
import FieldCalculation from "../component/Fields/Calculation.js";
import Checklist from "../component/Fields/Checklist";

import FieldQ1 from "../component/Fields/Q1.js";
import FieldQ2 from "../component/Fields/Q2";
import FieldQ3 from "../component/Fields/Q3";
import FieldQ4 from "../component/Fields/Q4";
import FieldQ5 from "../component/Fields/Q5";
import FieldQ6 from "../component/Fields/Q6";
import FieldQ7 from "../component/Fields/Q7";
import FieldQ8 from "../component/Fields/Q8";
import FieldQ9 from "../component/Fields/Q9";
import FieldQ10 from "../component/Fields/Q10";
import FieldQ11 from "../component/Fields/Q11";
import FieldQ11b from "../component/Fields/Q11b";
import FieldQ12 from "../component/Fields/Q12";
import FieldQ13 from "../component/Fields/Q13";
import FieldSmart from "../component/Fields/Smart";
import LikertTable from "../component/Fields/Likert";

import TimerHtml from "../component/Fields/Timer";
import ReductionTable from "../component/Fields/ReductionValues";
import SliderHtml from "../component/Fields/Slider";
import FieldSig from "../component/Fields/Sig";

function getQueType(type,id){
	switch(type){
		case 'sig':
			return <i className="fas fa-edit" onClick={()=>showSign2(type,id)}></i>
		break;
		case 'q10':
			return <Checkbox id={'type'+id} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} onClick={()=>showSign(type,id)}/>
		break;
		default:
			return null
	}
}

function showSign(type,id){
	if($("#type"+id).prop("checked") == true){
		$('#'+type+id).css('display','block');
	}else if($("#type"+id).prop("checked") == false){
		$('#'+type+id).css('display','none');
	}
}
	
function showSign2(type,id){
	$('#'+type+id).css('display','block')
}

function generatehtmlforFiled(comment,callback=null,kioskResponse,apiPdfs,outputText){
	const rowData = {
		fieldId: comment[0],
		inputText: outputText ? comment[9] : comment[4],
		outputText: comment[9],
		required: comment[15],
		options: comment[6],
		labels: comment[41],
		validation: comment[48],
		type: comment[5],
		typeMode: comment[138],
		numberColumn: comment[136],
		arraignment: comment[138],
		buttonLabel: comment[40],
		fieldGuid: comment[117],
		min: comment[50],
		max: comment[51],
		step: comment[36],
		link: comment[78],
		analzeVideo: comment[59],
		pdfReport: comment[60],
		dataLink: comment[99],
		kioskResponse: kioskResponse,
		preView:true,
	};
    switch(comment[5].toLowerCase()) {
        case 'parent header':
			return (<div className={'col-md-12'}>
				<h3> {comment[4]}</h3>
			</div>);
            
        break;
		case 'child header 1':
			return (<div className={'col-md-12'}>
				<h4> {comment[4]}</h4>
			</div>);
            
        break;
		case 'child header 2':
			return (<div className={'col-md-12'}>
				<h5> {comment[4]}</h5>
			</div>);
            
        break;
		case 'child header 3':
			return (<div className={'col-md-12'}>
				<h6> {comment[4]}</h6>
			</div>);
        break;
		
		case 'title':
			return (<div className={'col-md-12'}>
				<h5> {comment[4]}</h5>
			</div>);
        break;
		
		case 'text':
			return (<div className={'col-md-12'}>
				<b> {comment[4]}</b>
			</div>);
        break;
		
		case 'q1':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldQ1 data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'q2':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldQ2 data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'q3':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldQ3 data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'q4':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldQ4 data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'q5':
			return (<div className={'col-md-12'}>
				<div><FieldQ5 data={rowData} func={callback} /> {comment[4]}</div>
			</div>);
        break;
		
		case 'q6':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldQ6 data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'q7':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldQ7 data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'q8':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldQ8 data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'q9':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldQ9 data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'q10':
			return (<div className={'col-md-12'}>
				<p>{getQueType(comment[5].toLowerCase(),comment[0])} {comment[4]}</p>
				<div><FieldQ10 data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'q11':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldQ11 data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'q11b':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldQ11b data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'q12':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldQ12 data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'q13':
			return (<div className={'col-md-12'}>
				<div><FieldQ13 data={rowData} func={callback} /></div>
			</div>);
        break;

		case 'link':
			let Link = FieldLink(rowData);
			return Link;
            
        break;
		
		case 'smart':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldSmart data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'likert':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><LikertTable data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'timer':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><TimerHtml data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'video':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldVideo dataField={''} data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'file':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div><FieldFile dataField={''} data={rowData} func={callback} /></div>
			</div>);
        break;
		
		case 'launchpad':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<Launchpad data={rowData} func={callback} />
			</div>);
        break;
		
		case 'image':
			return (<div className={'col-md-12'}>
				<FieldInlineImage data={rowData} func={callback} />
				<p>{comment[4]}</p>
			</div>);
        break;
		
		case 'inlinevideo':
			return (<div className={'col-md-12'}>
				<FieldInlineVideo data={rowData} func={callback} />
				<p>{comment[4]}</p>
			</div>);
        break;
		
		case 'pdfapi':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<FieldPdfApi data={rowData} apiPdfs={apiPdfs} func={callback} />
			</div>);
        break; 
		
		case 'apivideo':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<ApiFieldVideo data={rowData} func={callback} />
				<p>This will allow the upload of video for analysis.</p>
			</div>);
        break;
		
		case 'reduction':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<ReductionTable data={rowData} func={callback} />
			</div>);
        break;
		
		case 'slider':
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<SliderHtml data={rowData} func={callback} />
			</div>);
        break;
		
		
		
		case 'sig':
			return (<div className={'col-md-12'}>
				<p>{getQueType(comment[5].toLowerCase(),comment[0])} {comment[4]}</p>
				<FieldSig data={rowData} func={callback} />
			</div>);
        break;
		
		case 'slider':
			let slider = SliderHtml(rowData);
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div>{slider}</div>
			</div>);
        break;
		
		
		case 'basicgrid':
			let BasicGrid = BasicGridTable(rowData);
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div>{BasicGrid}</div>
			</div>);
        break;
		
		case 'basicgrid2':
			let BasicGrid2 = BasicGrid2Table(rowData);
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div>{BasicGrid2}</div>
			</div>);
        break;
		
		case 'grid5point':
			let Grid5Point = Grid5PointTable(rowData);
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div>{Grid5Point}</div>
			</div>);
        break;
		
		case 'gridcheck':
			let GridCheck = GridCheckTable(rowData);
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div>{GridCheck}</div>
			</div>);
        break;
		
		case 'weighted':
			let Weighted = WeightedCalTable(rowData);
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div>{Weighted}</div>
			</div>);
        break;
		
		case 'grid-dropdown':
			let GridDropdown = GridDropdownTable(rowData);
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div>{GridDropdown}</div>
			</div>);
        break;
		
		case 'grid-multicheck':
			let GridMultiCheck = GridMultiCheckTable(rowData);
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div>{GridMultiCheck}</div>
			</div>);
        break;
		
		case 'grid-singlecheck':
			let GridSingleCheck = GridSingleCheckTable(rowData);
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div>{GridSingleCheck}</div>
			</div>);
        break;
		
		/* case 'reduction':
			let Reduction = ReductionTable(rowData);
			return (<div className={'col-md-12'}>
				<p>{comment[4]}</p>
				<div>{Reduction}</div>
			</div>);
        break; */
		
		case 'assessment':
			return (<div className={'col-md-12'}>
				<FieldAssessment fieldId={comment[0]} required={comment[15]} question={comment[4]} NoteHTML={''} NoteModal={''} />
			</div>);
        break;
		
		case 'subjective':
			return (<div className={'col-md-12'}>
				<FieldSubjective fieldId={comment[0]} required={comment[15]} question={comment[4]} NoteHTML={''} NoteModal={''} />
			</div>);
        break;
		
		case 'calculation':
		case 'wcalculation':
			return (<div className={'col-md-12'}>
				<FieldCalculation fieldId={comment[0]} question={comment[4]} />
				<p>This will show the calculation from data entered in other fields</p>
			</div>);
        break;
		
		case 'checklist':
			return (<div className={'col-md-12'}>
				<Checklist fieldId={comment[0]} required={comment[15]} fieldGuid={comment[117]} question={comment[4]} NoteHTML={''} NoteModal={''} outputText={comment[9]}/>
			</div>);
        break;
		
        default:
			return  '';
    }
}


export {generatehtmlforFiled}; 

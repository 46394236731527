import React from 'react'; 

export default function FieldQ1(props) {
	let id = props.data.fieldId;
	let options = props.data.options.split(',');
	let kioskResponse = props.data.kioskResponse;
	let optionsHtml = '';
	if(options){
		optionsHtml = options.map(function(val,i) {
			return (
				<option value={val} key={i}> {val} </option>
			);
		})
	}
	
	const handleChange = (event) => {
        props.func(id,event.target.value);
    }
	
	return (<select id={"select-"+id} value={kioskResponse.size > 0 ? kioskResponse.get(id) : ''} className="form-control" onChange={handleChange}>
		<option value=""> Please Select</option>
		{optionsHtml}
	</select>);
}

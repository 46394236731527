import React, { Component } from "react";
import 'bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import config from "../config";
import { gapi } from 'gapi-script';
import Timer from 'react-timer';
import Slider from 'react-input-slider';
import axios,{post,get} from 'axios'; 
import SecondsTohhmmss from './SecondsTohhmmss'
import PropTypes from 'prop-types'


class PQ1 extends Component {
	constructor(props) {
		super(props);
    }
	
    render() {
        const {kioskResponse}=this.props;
		let id = this.props.rowAllData[0];
        let newArray =[];
		
        let response = kioskResponse.get(id);
		let options = this.props.rowAllData[6];
		let res = [];
        if(options){
            res = options.split(',');
        }
        let optionsHtml = res.map(function(val,i) {
			if(response == val){
				return (
					<option className="pdf-select" value={val} selected="selected" key={i}>{val}</option>
				);  
			}
		})
		
        return (
            <select style={{width: "320px"}} className="form-control">
                {optionsHtml}
            </select>
        );
    }
}

class PQ2 extends Component {
    constructor(props) {
        super(props); 
    }
    
    render() {
        const {kioskResponse}=this.props;
		let id = this.props.rowAllData[0];
        let newArray =[];
		
        let response = kioskResponse.get(id);
		let options = this.props.rowAllData[6];
		let res = [];
        if(options){
            res = options.split(',');
        }
        let optionsHtml = res.map(function(val,i) {
			if(response == val){
				return (
					<option className="pdf-select" value={val} selected="selected" key={i}>{val}</option>
				);  
			}
		})
		
        return (
            <select style={{width: "320px"}} className="form-control">
                {optionsHtml}
            </select>
        );
    }
}

class PQ3 extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {kioskResponse}=this.props;
        let id  = this.props.rowAllData[0];
        let newArray  =[];
        if(kioskResponse.size > 0){
            let multiRes = kioskResponse.get(id);
            if(multiRes !== undefined){
                newArray = multiRes.split(',')
            }
        }

        let options = this.props.rowAllData[6];
		let res = [];
        if(options){
            res = options.split(',');
        }
		const listyle = {
		  background: "#ccc"
		};
		
		let optionsHtml = '';
        if(res){
            optionsHtml = res.map(function(courthouse,i) {
                if(newArray.indexOf(courthouse) !== -1){
                    return (
                        <li style={listyle}>{courthouse}</li>
                    );  
                }else{
                    return (
                        <li>{courthouse}</li>
                    );
                }
            })
        }
		
		const ulstyle = {
		  border: "1px solid #ccc",
		  listStyleType: "none",
		  padding: "5px",
		  width: "220px"
		};
	
        return (
            <ul style={ulstyle}>
                {optionsHtml}
            </ul>
		);
    }
}

class PQ4 extends Component {
    constructor(props) {
		super(props);
    }
        
    
    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[0];
        let ts = '';
        if(kioskResponse.size > 0){
            let resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns;
            }
        }
        let options = this.props.rowAllData[6];
		let res = [];
        if(options){    
            res = options.split(',')
        }
		let optionsHtml = ''
        if(res){
            optionsHtml = res.map(function(val,i) {
                let name = 'actions'+id;

                return (
                    <div className="form-check mb-2" key={i}>
                        <label className="form-check-label">
                            <input className={ts == val ? "pdf-radio" : ''} type="radio" value={val} checked={ts == val} name={name}/> {val} 
                        </label>
                    </div>
                );
            })
        }
        return (
            <div onChange={this.shoot}>
               {optionsHtml}
            </div>
         
        );
    }
}

class PQ5 extends Component {
    constructor(props) {
	super(props);
		this.state = {
			q5action:''   
		}
		this.shoot = this.shoot.bind(this); 
	}
	shoot(event) {
		var isChecked= event.target.checked;
		var value = '';
		if(isChecked == true){
			value = 'checked';
		}else{
			value = 'not-checked';
		}
		this.setState({q5action:isChecked});
		this.props.func(value,this.props.rowAllData[2],this.props.rowAllData[8]);
	}
	render(){
		const {kioskResponse}=this.props;
		var options = this.props.rowAllData[5];
		var id = this.props.rowAllData[8];
		var ts=[];
		if(kioskResponse.size > 0){
			var resAns = kioskResponse.get(id)
		}
		return (
			<input type="checkbox" checked={kioskResponse.get(id) == 'checked' ? true : this.state.q5action}  onChange={this.shoot}/>           
		);

	}
}

class PQ6 extends Component {
    constructor(props) {
        super(props);
            this.state={
                q6:'',
            }
        this.shoot = this.shoot.bind(this); 
    }
    
    shoot(event) {
        this.setState({q6:event.target.value})
        this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
    render(){
        const {kioskResponse}=this.props;
        let id  = this.props.rowAllData[8];
        return (
            <input style={{width: "320px"}} type="text" className="form-control" value={kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.q6} onChange={this.shoot} />    
        );
    }
}
class PQ7 extends Component {
    constructor(props) {
        super(props);
        this.state={
            q7:'',
        }
        this.shoot = this.shoot.bind(this); 
    }
    shoot(event) {
        this.setState({q7:event.target.value})
       this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
    render() {
        const {kioskResponse}=this.props;
        let id  = this.props.rowAllData[8];
        return (
       
            <div className="form-group">
                <textarea style={{width: "320px"}} className="form-control" rows="2" id="comment" value={kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.q7} onChange={this.shoot}></textarea>
            </div>
        
        );
    }
}
class PQ8 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
        };
        this.handleChange = this.handleChange.bind(this); 
        
    }
    handleChange = date => {
        var newDate = date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear();
        this.props.func(newDate,this.props.rowAllData[2],this.props.rowAllData[8]);
        this.setState({
          startDate: date,date
        });
      };

    render(){
        const {kioskResponse}=this.props;
        let id= this.props.rowAllData[8];
        if(kioskResponse.size > 0 ){
            var dateString = kioskResponse.get(id);
            if(dateString !== undefined){
                var dateParts = dateString.split("/");
                var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            }
        }
        return(
            <DatePicker 
              selected={dateObject ? dateObject : this.state.startDate} 
              onChange={this.handleChange}
            />
        );
    }
}
class PQ9 extends Component {
    constructor(props) {
    super(props);
        this.state={
            q9:'',
        }
        this.shoot = this.shoot.bind(this); 
    }     
    shoot(event) {
        this.setState({q9:event.target.value})
        this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
    render() {
        const {kioskResponse}=this.props;
        let id  = this.props.rowAllData[8];
        return (
            <div className="form-group">
                <textarea style={{width: "320px"}} className="form-control" rows="15" id="comment" value={kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.q9} onChange={this.shoot}></textarea>
            </div>
        
        );
    }
}
class PSig extends Component {
    constructor(props){
        super(props)
        this.state ={
            signName:'',
            signTitle:'',
            signCont:'',
            signDate:'',
            errorName:'',
        }
    }
    getvalue=(event)=>{
        let id = this.props.rowAllData[8];
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[event.target.name]:event.target.value})
        if(name == "signDate"){
            var nowDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}); 
            var date = nowDate;
            $('#signDate'+id).val(date);
            $('#signDate'+id).attr('readonly', true);
            this.setState({[event.target.name]:date})
        }
        if(name == 'signName' && value !==''){
            this.setState({errorName:''});
        }
    }
    handleSave=()=>{
        var nowDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
        let id = this.props.rowAllData[8];
        let name =this.state.signName;
        let title =this.state.signTitle;
        let contId = this.state.signCont;
        let signDate = this.state.signDate ;
        let res = name+','+title+','+contId+','+nowDate;
        $('#signDate'+id).val(nowDate);
        $('#signDate'+id).attr('readonly', true);
        if(name !== ''){
            this.props.func(res,this.props.rowAllData[2],this.props.rowAllData[8]);
        }else{
            this.setState({errorName:'please enter your name'});
        }
    }
    render(){
        const {errorName} = this.state;
        const {kioskResponse}=this.props;
        let id  = this.props.rowAllData[8];
        if(kioskResponse.size > 0){
            var multiRes = kioskResponse.get(id);
            if(multiRes !== undefined){
                var newArray = multiRes.split(',');
            }
        }
        return (
            <div className="form-horizontal" id={"sig"+id} style={{display: newArray ? 'block':'none'}}>    
                <div style={{padding: "4px"}} className="form-group">
                    <label style={{width: "130px"}} className="label-control">Name<span className="required">*</span></label>
                    <input style={{width: "210px"}} type="text" name="signName" placeholder="enter your name" value={newArray !== undefined ? newArray[0] :this.state.signName} onChange={this.getvalue}/>
                    {errorName ? <p className="alert alert-danger"> { this.state.errorName }</p> : null}
                </div>
                <div style={{padding: "4px"}} className="form-group">
                    <label style={{width: "130px"}} for="exampleFormControlInput1">Title</label>
                    <input style={{width: "210px"}} type="text" name="signTitle" placeholder="enter your title" value={newArray !== undefined ? newArray[1]:this.state.signTitle} onChange={this.getvalue}/>
                </div>
                <div style={{padding: "4px"}} className="form-group">
                    <label style={{width: "130px"}} for="exampleFormControlInput1">ID or Contact info</label>
                    <input style={{width: "210px"}} type="text" name="signCont" placeholder="enter your id or contact info" value={newArray !== undefined ? newArray[2]:this.state.signCont} onChange={this.getvalue}/>
                </div>
                <div style={{padding: "4px"}} className="form-group">
                    <button style={{width: "150px"}} onClick={this.handleSave}><i className="fa fa-save"></i>Save and Date</button>
                    <input style={{width: "190px"}} type="text" name="signDate" id={"signDate"+id} value={newArray !== undefined ? newArray[3]+','+ newArray[4] :''} placeholder="fills date and time dd/mm/yyyy hhmmss" onClick={this.getvalue}/>
                </div>
            </div>
        );

    }
}
let offset = null, interval = null

class PTimers extends Component {
  static get propTypes () {
    return {
      options: PropTypes.object
    }
  }

  constructor(props) {
    super(props)
    this.state = { clock: 0, time: '' }
    
  }

  componentWillUnmount() {
    this.pause()
  }

  pause() {
    if (interval) {
      clearInterval(interval)
      interval = null
    }
  }
  play() {
    const OPTIONS = { prefix: 'seconds elapsed!', delay: 100}
    if (!interval) {
      offset = Date.now()
      interval = setInterval(this.update.bind(this), OPTIONS.delay)
    }
  }

  reset() {
    let clockReset = 0
    this.setState({clock: clockReset })
    let time = SecondsTohhmmss(clockReset / 1000)
    this.setState({time: time })
  }
  stop(){
    if (interval) {
        this.props.func(this.state.time,this.props.rowAllData[2],this.props.rowAllData[8]);
        clearInterval(interval)
        interval = null
    }
  }
  update() {
    let clock = this.state.clock
    clock += this.calculateOffset()
    this.setState({clock: clock })
    let time = SecondsTohhmmss(clock / 1000)
    this.setState({time: time })
  }

  calculateOffset() {
    let now = Date.now()
    let newOffset = now - offset
    offset = now
    return newOffset
  }

  render() {
    const {kioskResponse}=this.props;
        let id  = this.props.rowAllData[8];
        if(kioskResponse.size > 0){
            var multiRes = kioskResponse.get(id);
            if(multiRes !== undefined){
                var newArray = multiRes
            }
        }
    const timerStyle = {
      margin: "0px",
    };

    const buttonStyle = {
      background: "#fff",
      color: "#666",
      border: "1px solid #ddd",
      marginRight: "5px",
      padding: "10px",
      fontWeight: "200"
    };

    const secondsStyles = {
      fontSize: "200%",
      fontWeight: "200",
      lineHeight: "1.5",
      margin: "0",
      color: "#666"
    };

    return (
      <div style={timerStyle} className="react-timer">
        <h3 style={secondsStyles} className="seconds"> {newArray ? newArray : this.state.time} {this.props.prefix}</h3>
      </div>
    )
  }
}

class PSliders extends Component {
    constructor(props){
        super(props) 
    }
   
    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                var ts = resAns;
            }
        }
        var options = this.props.rowAllData[5];
        if(options){
            var res = options.split(',');
        }
        return (
            <div className="row">
                <table className="slider-table" style={{width: "320px"}} >
				  <tr>
					<td>{ res !== undefined ? res[0]:null}</td>
					<td className="slider-td"><progress id="file" value={ts} max="100"></progress></td>
					<td>{res !== undefined ? res[1]:null}</td>
				  </tr>
				</table>
          </div> 
        );
    }
}

class PQ10 extends Component {
    constructor(props) {
    super(props);
    this.state = {
        description:''
    }
    this.shoot = this.shoot.bind(this); 
    }
    
    shoot(event) {
        this.setState({description:event.target.value})
        this.props.func(event.target.value,this.props.rowAllData[2],this.props.rowAllData[8]);
    }
    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                var ts = resAns;
            }
        }
        return (
            <div id={"q10"+id}  style={{display: ts ? 'block':'none'}}>
                 Description : <input style={{width: "320px"}} text="type" className="form-control" value={ts ? ts : this.state.description} onChange={this.shoot}/>
            </div>
        );
    }

}
class PQ11 extends Component {

    constructor(props) {
    super(props);
        this.state = { checkedItems: new Map(),itemArray:[] }
         
    }
    render(){
        const {kioskResponse}=this.props;
        const {checkedItems}= this.state;
        var id = this.props.rowAllData[8];
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        var options = this.props.rowAllData[5];
        if(options){    
            var res = options.split(',')
        }

        if(res){
            var optionsHtml = res.map(function(courthouse,i) {
            var name = 'action'+i;
            if(ts.indexOf(courthouse) !== -1){
                return (
                    <div className="form-check mb-2" key={i}>
                        <label className="form-check-label">
                            <input type="checkbox" checked='checked' value={courthouse}  name={name}/> {courthouse} 
                        </label>
                    </div>
                );  
            }else{
                return (
                    <div className="form-check mb-2" key={i}>
                        <label className="form-check-label">
                            <input type="checkbox" value={courthouse} name={name}/> {courthouse} 
                        </label>
                    </div>
                );
                }
            })
        }
        return (
            <div>
               {optionsHtml}
            </div>
         
        );
    }

}
class PQ11B extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            checkedItems: new Map(),itemArray:[] 
        }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const item = event.target.name;
        const isChecked = event.target.checked;
        var id = this.props.rowAllData[8];
        const value =event.target.value;
        let checkedItems = this.state.checkedItems;
        let itemArray = this.state.itemArray
        itemArray.push(value)
        let itemString = itemArray.toString();
        let sh = {name:item, value:value, isChecked:isChecked};
        if(isChecked==true){
            $('#q11b'+item).css('display','block');
            checkedItems.set(item, sh)
        }else{
            $('#q11b'+item).css('display','none');
            checkedItems.delete(item) 
        }
        this.setState({checkedItems});
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
    render(){
        const {kioskResponse}=this.props;
        const {checkedItems}= this.state;
        var id = this.props.rowAllData[8];
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        var options = this.props.rowAllData[5];
        if(options){    
            var res = options.split(',')
        }

        if(res){
            var optionsHtml = res.map(function(courthouse,i) {
                var name = 'action'+i;
                return (
                    <div className="form-check mb-2" key={i}>
                        <label className="form-check-label">
                            <input type="checkbox" value={courthouse} name={name}/> {courthouse} 
                        </label>
                        <div id={"q11b"+name} style={{display:'none'}} >
                            Description : <input style={{width: "320px"}} text="type" className="form-control" />
                        </div>
                    </div>
                );
            })
        }
        return (
            <div onChange={this.shoot}>
               {optionsHtml}
            </div>
         
        );
    }
}
class PQ12 extends Component {
    
    render(){
      return(
        <div>
        </div>
      );
    }

}
class PQ13 extends Component {
    
    render(){
      return(
        <div>
        </div>
      );
    }

}

class PQ14 extends Component {
    
    render(){
      return(
        <div>
        </div>
      );
    }

}
 
class PImage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			images: [],
			imageUrls: [],
			message: ''
		}
	}			

	selectImages = (event) => {
		let images = []
		for (var i = 0; i < event.target.files.length; i++) {
			images[i] = event.target.files.item(i);
		}
		images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif)$/))
		let message = `${images.length} valid image(s) selected`
		this.setState({ images, message })
	}

	uploadImages = () => {

		const uploaders = this.state.images.map(image => {
        const data = new FormData();
        data.append("image", image, image.name); 
        const url = 'http://localhost:3000/public';
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'content-type': 'multipart/form-data'
            }
        }
        return  post(url, data,config)
            .then(response => {
                this.setState({
                    imageUrls: [ response.data.imageUrl, ...this.state.imageUrls ]
                });
            })
        });
     
        axios.all(uploaders).then(() => {
            alert('data uploaded successfully');
        }).catch(err => alert(err.message));
    }
 
	render() {
		return (
		<div>
			<br/>
			<div className="col-sm-12">
				<div className="col-sm-4">
					<input className="form-control " type="file" onChange={this.selectImages} multiple/>
					<button className="btn btn-primary" value="Submit" onClick={this.uploadImages}>Submit</button>
				</div>
				<p className="text-info">{this.state.message}</p>
			</div>
			<div className="row col-lg-12">
				{ this.state.imageUrls.map((url, i) => (
					<div className="col-lg-2" key={i}>
						<img src={'/public' + url} className="img-rounded img-responsive" alt="not available"/><br/>
					</div>
				))}
			</div>
		</div>
    )}
}

class PQ15 extends Component {
    
    render(){
      return(
        <div>
        </div>
      );
    }

}

class PQ16 extends Component {
    constructor(props) {
        super(props);
        this.myArray = [];
        this.state = {
            showdata : this.myArray,
            postVal : "",
            users: '',
            newoption:'',
        }
        this.shoot = this.shoot.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);  
    }
    
    shoot(event) {
        this.setState({postVal:event.target.value});
    }
    handleSubmit() {
        this.myArray.push(this.state.postVal);
        this.setState({postVal:''});
    }
  
    render() {
        var options = this.myArray;
        var optionsHtml = '';
        if(options.length !== 0 && options.length !== 1){
            var res = options.split(',')
        }else{
            optionsHtml = <option value={options}>{options}</option>   
        }
        if(res){
            optionsHtml = res.map(function(courthouse,i) {
                return (
                    <option value={courthouse} key={i}>{courthouse}</option>
                );
            })
        }
        return (
            <div className="abc">
                <div className="input-group mb-3">
                    <input style={{width: "320px"}} type="text" className="form-control" id="select1sa" onChange={this.shoot}/>
                </div>
                <select className="form-control">
                <option value=""> Please Select</option>
                    {optionsHtml}
                </select>
            </div>
        );
    }
}

class PSubjective extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sub:'',
        }
    }
    
    render() {
        const {kioskResponse, username, kioskUserName, moduleName}=this.props
		let id = this.props.rowAllData[8];

        return (
            <div className="subjective">
				{ kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.sub }
            </div>
        );
    }
}

class PAssessment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sub:'',
        }
    }
    
    render() {
        const {kioskResponse, username, kioskUserName, moduleName}=this.props
		let id = this.props.rowAllData[8];

        return (
            <div className="assessment">
				{ kioskResponse.size > 0 ? kioskResponse.get(id) : this.state.sub }
            </div>
        );
    }
}

class PGrid extends Component {

    constructor(props) {
    super(props);
        this.state = { itemArray:[] }
    }
        
    
    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        var options = this.props.rowAllData[9];
		
		if(options){    
            var str = options.split('_');
            var headers = str[0].split(',');
            var rows = str[1].split(',');
			
			
        }
		
		if(headers){
			var headerHtml = headers.map(function(headerText,i) {
				return (	
				<th>{headerText}</th>
				);
			})
		}

        if(rows){
            var optionsHtml = rows.map(function(rowText,i) {
				return (	
					<tr>
						<td>
						{rowText}
						</td>
						{
							headers.map(function(headerText,i) {
								if(ts.indexOf(rowText+':'+headerText) !== -1){
									return(	
									<td style={{textAlign: "center"}}>
										<input type="radio" value={headerText}  name={'P'+rowText} checked='checked' />
									</td>);
								}else{
									return(	
									<td style={{textAlign: "center"}}>
										<input type="radio" value={headerText}  name={rowText}/>
									</td>);
								}
							})
							
						}
					</tr>
				);
            }) 
        }
		
        return (
            <div>
				<table>
				<tr>
					<th></th>
				    {headerHtml}
				</tr>
				{optionsHtml}
				</table>
               
            </div>
         
        );
    }

}

class PGridMultiCheck extends Component {
	constructor(props) {
		super(props);
    }
        
   render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
		var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
			if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
		
		const border = {
		  border: "1px solid #ccc",
		  borderCollapse: "collapse",
		  padding:"5px",
		};
		
        var options = this.props.rowAllData[9];
		var title = this.props.rowAllData[3];
		if(options){    
            var str = options.split('_');
            var headers = str[0].split(',');
            var rows = str[1].split(',');
			
			
        }
		
		if(headers){
			var headerHtml = headers.map(function(headerText,i) {
				headerText = headerText.split(':');
				return (	
				<th style={border}>
				{headerText[0]}
				</th>
					
				);
			})
		}

        if(rows){
            var optionsHtml = rows.map(function(rowText,i) {
				var name = 'action'+i;
				let r = i+1;
				return (	
					<tr>
						<td style={border}>
						{rowText}
						</td>
						{
							headers.map(function(headerText,j) {
								let c = j+1;
								let headerStr = headerText.split(':');
								let response = '';
								if(ts){
									ts.map(function(resStr,i) {
										let rowCol = resStr.split(':');
										if(rowCol[0] == 'R'+r+'-C'+c){
											response = rowCol[1];
										}
									})
								}
								
								if(response){
									return(	
									<td style={border}>
										<input type="checkbox" className={'R'+r+'-C'+c+'-'+id} value={response}  name={'R'+r+'-C'+c} maxLength={25} checked='checked'/>
									</td>);
								}else{
									return(	
									<td style={border}>
										<input type="checkbox" className={'R'+r+'-C'+c+'-'+id}  name={'R'+r+'-C'+c} maxLength={25} />
									</td>);
								}
							})
							
						}
					</tr>
				);
            }) 
        }
		
        return (
            <table style={border}>
				<tr>
					<th style={border}>Question</th>
				    {headerHtml}
				</tr>
               {optionsHtml}
            </table>
         
        );
    }
}

class PGridSingleCheck extends Component {

    constructor(props) {
    super(props);
        this.state = { itemArray:[] }
    }
        
    
    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
		
		const border = {
		  border: "1px solid #ccc",
		  borderCollapse: "collapse",
		  padding:"5px",
		};
		
        var options = this.props.rowAllData[9];
		
		if(options){    
            var str = options.split('_');
            var headers = str[0].split(',');
            var rows = str[1].split(',');
			
			
        }
		
		if(headers){
			var headerHtml = headers.map(function(headerText,i) {
				return (	
				<th style={border}>{headerText}</th>
				);
			})
		}

        if(rows){
            var optionsHtml = rows.map(function(rowText,i) {
				return (	
					<tr>
						<td style={border}>
						{rowText}
						</td>
						{
							headers.map(function(headerText,i) {
								if(ts.indexOf(rowText+':'+headerText) !== -1){
									return(	
									<td style={border}>
										<input type="radio" value={headerText}  name={'P'+rowText} checked='checked' />
									</td>);
								}else{
									return(	
									<td style={border}>
										<input type="radio" value={headerText}  name={rowText}/>
									</td>);
								}
							})
							
						}
					</tr>
				);
            }) 
        }
		
        return (
            <div>
				<table style={border}>
				<tr>
					<th style={border}>Question</th>
				    {headerHtml}
				</tr>
				{optionsHtml}
				</table>
               
            </div>
         
        );
    }

}

class PBasicGrid extends Component {

    constructor(props) {
		super(props);
    }
        
   render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
		var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
			if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
		
		const border = {
		  border: "1px solid #ccc",
		  borderCollapse: "collapse",
		  padding:"5px",
		};
		
        var options = this.props.rowAllData[9];
		var title = this.props.rowAllData[3];
		if(options){    
            var str = options.split('_');
            var headers = str[0].split(',');
            var rows = str[1].split(',');
			
			
        }
		
		if(headers){
			var headerHtml = headers.map(function(headerText,i) {
				headerText = headerText.split(':');
				return (	
				<th style={border}>
				{headerText[0]}
				</th>
					
				);
			})
		}

        if(rows){
            var optionsHtml = rows.map(function(rowText,i) {
				var name = 'action'+i;
				let r = i+1;
				return (	
					<tr>
						<td style={border}>
						{rowText}
						</td>
						{
							headers.map(function(headerText,j) {
								let c = j+1;
								let headerStr = headerText.split(':');
								let response = '';
								if(ts){
									ts.map(function(resStr,i) {
										let rowCol = resStr.split(':');
										if(rowCol[0] == 'R'+r+'-C'+c){
											response = rowCol[1];
										}
									})
								}
								
								if(response){
									return(	
									<td style={border}>
										{response}
									</td>);
								}else{
									return(	
									<td style={border}>
										&nbsp;
									</td>);
								}
							})
							
						}
					</tr>
				);
            }) 
        }
		
        return (
            <table style={border}>
				<tr>
					<th style={border}>Field names defined by SOOGAP</th>
				    {headerHtml}
				</tr>
               {optionsHtml}
            </table>
         
        );
    }

}

class PGridDropdown extends Component {

    constructor(props) {
		super(props);
    }

    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
		var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
			if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
		
		const border = {
		  border: "1px solid #ccc",
		  borderCollapse: "collapse",
		  padding:"5px",
		};
		
		//console.log('rowAllData->',this.props.rowAllData);
		var options = this.props.rowAllData[9];
        var title = this.props.rowAllData[3];
		
		if(options){    
            var str = options.split('_');
            var headers = str[0].split(',');
            var rows = str[1].split('|');

        }
		
		if(headers){
			var headerHtml = headers.map(function(headerText,i) {
				//headerText = headerText.split(':');
				return (	
				<th style={border}>
				{headerText}
				</th>
					
				);
			})
		}

        if(rows){
            var optionsHtml = rows.map(function(rowText,i) {
				let r = i+1;
				let row = rowText.split(':');
				
				let col1Html = '';
				let col2Html = '';
				
				if(row[1] !== undefined){
					let options = row[1].split(',');
							
					col1Html = options.map(function(opt,i) {
						return (
							<option value={opt} key={i}>{opt}</option>
						);
					})
				}
				
				if(row[2] !== undefined){
					let options = row[2].split(',');
							
					col2Html = options.map(function(opt,i) {
						return (
							<option value={opt} key={i}>{opt}</option>
						);
					})
				}
				
				let response1 = '';
				let response2 = '';
				if(ts){
					ts.map(function(resStr,n) {
						let rowCol = resStr.split(':');
						if(rowCol[0] == 'R'+r+'-C2' && rowCol[1]){
							response1 = rowCol[1];
						}
						if(rowCol[0] == 'R'+r+'-C3' && rowCol[1]){
							response2 = rowCol[1];
						}
					})
				}
				
				return (	
					<tr>
						<td style={border}>
						{row[0]}
						</td>
						{col1Html ?
						<td style={border}>
							<select className="form-control" value={response1} name={'R'+r+'-C2'}>
								<option value=""> Please Select</option> 
								{col1Html}							
							</select>
						
						</td>
						:null}
						{col2Html ?
						<td style={border}>
							<select className="form-control" value={response2} name={'R'+r+'-C3'}>
								<option value=""> Please Select</option> 
								{col2Html}							
							</select>
						</td>
						:null}
						
					</tr>
				);
            }) 
        }
		
        return (
            <table style={border}>
				<tr>
					{headerHtml}
				</tr>
               {optionsHtml}
            </table>
         
        );
    }

}

class PGridCheck extends Component {

    constructor(props) {
    super(props);
        this.state = { checkedItems: new Map(),itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const item = event.target.name;
        const isChecked = event.target.checked;
        const value =event.target.value;
        let checkedItems = this.state.checkedItems;
        let itemArray = this.state.itemArray
        let sh = {name:item, value:value, isChecked:isChecked};

        if(isChecked==true){
            itemArray.push(value)
            checkedItems.set(item, sh)
        }else{
            if(itemArray.indexOf(value) !==-1){
                itemArray = itemArray.filter(e => e !== value)
            }
            checkedItems.delete(item) 
        }
        let itemString = itemArray.toString();
        this.setState({checkedItems});
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
	
    render(){
        const {kioskResponse}=this.props;
        const {checkedItems}= this.state;
        var id = this.props.rowAllData[8];
		let numberColumn = 2;
		this.props.allData.map(function(data,i) {
			if(data[0] == id){
				numberColumn = data[136] ? data[136] : 2;
			}
		})
		var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        var options = this.props.rowAllData[5];
        if(options){    
            var res = options.split(',')
        }
		
		const border = {
		  border: "1px solid #ccc"
		};
		
		const result = new Array(Math.ceil(res.length / numberColumn)).fill().map(_ => res.splice(0, numberColumn))
		
		if(result){
			var optionsHtml = result.map(function(res,i) {
				
				return (
					<tr>
					{
						res.map(function(courthouse,i) {
							var name = 'action'+i;
							if(ts.indexOf(courthouse) !== -1){
								return (
									<td style={border}>
										<label className="form-check-label">
											<input type="checkbox" checked='checked' value={courthouse}  name={name}/> {courthouse} 
										</label>
									</td>
								);  
							}else{
								return (
									<td style={border}>
										<label className="form-check-label">
											<input type="checkbox" value={courthouse} name={name}/> {courthouse} 
										</label>
									</td>
								);
							}
						})
						
					}
					</tr>
				);
				
			})	
        }
		
        return (
            <div className="row grid-check" onChange={this.shoot}>
				<table style={border}>
				{optionsHtml}
			   </table>
            </div>
         
        );
    }

}

class PLikert extends Component {

    constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const name = event.target.name;
        const value = event.target.value;
		let itemArray = this.state.itemArray
		itemArray[name] = name+':'+value;
		let newItemArray = []
		Object.keys(itemArray).map(function(key) {
           newItemArray.push(itemArray[key]);
        });
        let itemString = newItemArray.toString();
		
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
	
    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        var options = this.props.rowAllData[9];
		
		if(options){    
            var str = options.split('_');
            var headers = str[0].split(',');
            var rows = str[1].split(',');
			
			
        }
		
		/* if(headers){
			var headerHtml = headers.map(function(headerText,i) {
				return (	
				<div className="col-md-2">
				{headerText}
				</div>
					
				);
			})
		} */

        if(rows){
			
			let point = [1,2,3,4,5,6,7,8,9,10];
			
            var optionsHtml = rows.map(function(rowText,i) {
				var name = 'action'+i;
				return (	
					<tr>
						<td style={{width: "110px"}}>
						{rowText}
						</td>
						{
							 point.map(function(val,i) {
								if(ts.indexOf(rowText+':'+val) !== -1){
									return(	
									<td style={{width: "90px"}}>
										<input type="radio" value={val}  name={'P'+rowText} checked='checked' />
									</td>);
								}else{
									return(	
									<td style={{width: "90px"}}>
										<input type="radio" value={val}  name={rowText}/>
									</td>);
								}
							})
						}
					</tr>
				);
            }) 
        }
		
		const border = {
		  border: "1px solid #ccc"
		};
		
        return (
            <div onChange={this.shoot}>
				<table>
					<tr>
						<th style={{width: "70px"}}></th>
						<th style={{width: "125px"}}>{headers[0]}</th>
						<th style={{width: "90px"}}></th>
						<th style={{width: "90px"}}></th>
						<th style={{width: "90px"}}></th>
						<th style={{width: "90px"}}></th>
						<th style={{width: "90px"}}></th>
						<th style={{width: "90px"}}></th>
						<th style={{width: "90px"}}></th>
						<th style={{width: "90px"}}></th>
						<th style={{width: "125px" }}>{headers[1]}</th>
					</tr>
				</table>
				<table>	
					{optionsHtml}
			   </table>
            </div>
         
        );
    }

}

class PSmart extends Component {

    constructor(props) {
    super(props);
        this.state = { checkedItems: new Map(),itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const item = event.target.name;
        const isChecked = event.target.checked;
        const value =event.target.value;
        let checkedItems = this.state.checkedItems;
        let itemArray = this.state.itemArray
        let sh = {name:item, value:value, isChecked:isChecked};

        if(isChecked==true){
            itemArray.push(value)
            checkedItems.set(item, sh)
        }else{
            if(itemArray.indexOf(value) !==-1){
                itemArray = itemArray.filter(e => e !== value)
            }
            checkedItems.delete(item) 
        }
        let itemString = itemArray.toString();
        this.setState({checkedItems});
        this.props.func(itemString,this.props.rowAllData[2],this.props.rowAllData[8])
    }
    render(){
        const {kioskResponse}=this.props;
        const {checkedItems}= this.state;
        var id = this.props.rowAllData[8];
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        var options = this.props.rowAllData[5];
        if(options){    
            var res = options.split(',')
        }

        if(res){
            var optionsHtml = res.map(function(courthouse,i) {
            var name = 'action'+i;
            if(ts.indexOf(courthouse) !== -1){
                return (
                    <div className="form-check mb-2" key={i}>
                        <label className="form-check-label">
                            <input type="checkbox" checked='checked' value={courthouse}  name={name}/> {courthouse} 
                        </label>
                    </div>
                );  
            }else{
                return (
                    <div className="form-check mb-2" key={i}>
                        <label className="form-check-label">
                            <input type="checkbox" value={courthouse} name={name}/> {courthouse} 
                        </label>
                    </div>
                );
                }
            })
        }
        return (
            <div onChange={this.shoot}>
               {optionsHtml}
            </div>
         
        );
    }

}

class PReduction extends Component {
}
class PWeighted extends Component {

    constructor(props) {
		super(props);
    }
        
    render(){
        const {kioskResponse}=this.props;
        var id = this.props.rowAllData[8];
		let numberColumn = 2;
		this.props.allData.map(function(data,i) {
			if(data[0] == id){
				numberColumn = data[136] ? data[136] : 2;
			}
		})
		var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        var options = this.props.rowAllData[9];
        if(options){    
            var res = options.split(',')
        }
		
		const border = {
		  border: "1px solid #ccc"
		};
		
		const result = new Array(Math.ceil(res.length / numberColumn)).fill().map(_ => res.splice(0, numberColumn))
		
		if(result){
			var optionsHtml = result.map(function(res,i) {
				
				return (
					<tr>
					{
						res.map(function(courthouse,i) {
							var name = 'action'+i;
							var txt = courthouse.split(':');
							if(ts.indexOf(courthouse) !== -1){
								return (
									<td style={border}>
										<label className="form-check-label">
											<input type="checkbox" checked='checked' value={courthouse}  name={name}/> {txt[0]} 
										</label>
									</td>
								);  
							}else{
								return (
									<td style={border}>
										<label className="form-check-label">
											<input type="checkbox" value={courthouse} name={name}/> {txt[0]} 
										</label>
									</td>
								);
							}
						})
						
					}
					</tr>
				);
				
			})	
        }
		
        return (
            <div className="row grid-check">
				<table style={border}>
				{optionsHtml}
			   </table>
            </div>
         
        );
    }

}

export {PQ1,PQ2,PQ3,PQ4,PQ5,PQ6,PQ7,PQ8,PQ9,PQ10,PQ11,PQ12,PQ13,PQ14,PQ15,PQ16,PQ11B,PTimers,PSliders,PImage,PSig,PSubjective,PAssessment,PGrid,PBasicGrid,PGridMultiCheck,PGridSingleCheck,PGridCheck,PGridDropdown,PLikert,PSmart,PReduction,PWeighted};
import React from 'react';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './containers/App';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import Login from './component/Login';

import 'bootstrap/dist/css/bootstrap.min.css';

let subdirectory = $('#subdirectory').val();

const routing = (
  <Router>
      <Route path="/" component={App} />
      <Route path={subdirectory ? "/"+subdirectory+"/beta" : "/beta"} component={Login} />
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

serviceWorker.unregister();

import React, { Component } from 'react'; 
import $ from 'jquery';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

export default class FieldSmart extends Component {
	constructor(props) {
    super(props);
        this.state = {itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
		const item = event.target.name;
        const isChecked = event.target.checked;
        const value =event.target.value;
        let itemArray = this.state.itemArray
        let fieldId = this.props.data.fieldId;
		
		if(isChecked==true && value == 'None of the Above'){
			itemArray = [];
			this.setState({itemArray});
			$('.smart-'+fieldId).prop('checked', false);
			$('.smart-'+fieldId).prop('disabled', true);
		}else{
			$('.smart-'+fieldId).prop('disabled', false);
		}
		
        if(isChecked==true){
            itemArray.push(value);
        }else{
            if(itemArray.indexOf(value) !==-1){
                itemArray = itemArray.filter(e => e !== value)
            }
        }
		
		if(isChecked==true && value != 'None of the Above'){
			itemArray = $.grep(itemArray, function(value) {
			  return value != 'None of the Above';
			});
		}
		
		let itemString = itemArray.toString();
		this.props.func(this.props.data.fieldId,itemString);
    }
	
    render(){
        const {data} = this.props;
        let id = data.fieldId;
		let options = data.options.split(',');
		let kioskResponse = data.kioskResponse;
		//console.log('kioskResponse->',kioskResponse);
        let ts = '';
        if(kioskResponse.size > 0){
            let resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        
		if(ts.indexOf('None of the Above') !== -1){
			$('.smart-'+id).prop('disabled', true);
		}
		
		let optionsHtml = '';
        if(options){
            optionsHtml = options.map(function(val,i) {
				let name = 'action'+i;
				if(ts.indexOf(val) !== -1){
					return (<div className="form-check mb-2" key={i}>
						<label className="form-check-label">
							<input className={ val != 'None of the Above' ? "smart-input smart-"+id : ''} type="checkbox" checked='checked' value={val}  name={name}/> {val} 
						</label>
					</div>);  
				}else{
					return (<div className="form-check mb-2" key={i}>
						<label className="form-check-label">
							<input className={ val != 'None of the Above' ? "smart-input smart-"+id : ''} type="checkbox" value={val} name={name}/> {val} 
						</label>
					</div>);
				}
            })
        }
		
        return (
            <div onChange={this.shoot}>
               {optionsHtml}
            </div>
        );
    }
}

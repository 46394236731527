import React,{ Component } from 'react';
import $ from 'jquery';
import { Form,Button } from 'react-bootstrap';

class MainHeader extends Component {
	constructor(props) {
        super(props);
	 	this.state ={

	 	}
    }
   	handleChange(event){
    	this.setState({renderComponent:event.target.value});
    	this.props.getModule(event.target.value);
    }
	
	logout = () => {
		let subdirectory = $('#subdirectory').val();
        //localStorage.removeItem('id');
        localStorage.removeItem('username');
		if(subdirectory){
			window.location = '/'+subdirectory+'/';
		}else{
			window.location = '/';
		}
    }
	
    render(){
        const { builderOption, dataMode} =this.props;
		let date = new Date();
		let newDate = ("0" + (date.getMonth() + 1)).slice(-2)+''+date.getDate()+''+date.getFullYear();
    	return (
        <div className="card-header">
            <div className="row">
				<div className="col-md-6">
                    <h4>MDS Builder Tool</h4>
					<div className="instance-version">{'V1.05192022.a'}</div>
                </div>
				{dataMode == 'TestForm' ?
				<div className="col-md-2">
					<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
						<option value="mds_view">View Fields mode</option>
						<option value="status_tracker">Status Tracker</option>
					</select>
				</div>
				:
				<div className="col-md-2">
					<select id="select-view-option" className="form-control company-select" onChange={builderOption} >
						<option value="builder">Builder</option>
						<option value="mds_view">View Fields mode</option>
						<option value="mds_builder">MDS Manager</option>
						{localStorage.getItem('username') == 'admin' ?
							<option value="instance">Instance Manager</option>
						:null}
						{localStorage.getItem('username') == 'admin' ?
							<option value="trackerStatus">Status Tracker Manager</option>
						:null}
					</select>
				</div>
				}
            
                <div className="col-md-4">
                <div className="login-user-top">{'Logged in as: '+localStorage.getItem('username')}</div> <a className="nav-link btn btn-outline-info sign-out-btn" onClick={this.logout}>Sign Out</a>
                </div>
            </div>
        </div>            
    )}
	
}

export default MainHeader;
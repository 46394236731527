import React, { Component } from 'react'; 
import $ from 'jquery';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export default class FieldSig extends Component {
	constructor(props){
        super(props)
        this.state ={
            signName:'',
            signTitle:'',
            signCont:'',
            signDate:'',
            errorName:'',
        }
    }
	
    getvalue=(event)=>{
        let id = this.props.data.fieldId
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[event.target.name]:event.target.value})
        if(name == "signDate"){
            var nowDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}); 
            var date = nowDate;
            $('#signDate'+id).val(date);
            $('#signDate'+id).attr('readonly', true);
            this.setState({[event.target.name]:date})
        }
        if(name == 'signName' && value !==''){
            this.setState({errorName:''});
        }
    }
	
    handleSave=()=>{
        var nowDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
        let id = this.props.data.fieldId;
        let name =this.state.signName;
        let title =this.state.signTitle;
        let contId = this.state.signCont;
        let signDate = this.state.signDate ;
        let res = name+','+title+','+contId+','+nowDate;
        $('#signDate'+id).val(nowDate);
        $('#signDate'+id).attr('readonly', true);
        if(name !== ''){
            this.props.func(this.props.data.fieldId,res);
        }else{
            this.setState({errorName:'please enter your name'});
        }
    }
	
    render(){
		const {errorName} = this.state;
        const {data} = this.props;
        let id = data.fieldId;
		let kioskResponse = data.kioskResponse;
		
        if(kioskResponse.size > 0){
            var multiRes = kioskResponse.get(id);
            if(multiRes !== undefined){
                var newArray = multiRes.split(',');
            }
        }
        return (
            <div className="sig-horizontal" id={"sig"+id} style={{display: newArray ? 'block':'none'}}>    
                <div className="form-group">
                    
					<TextField id="filled-hidden-label-small" label="Name*" name="signName" variant="outlined" size="small" value={newArray !== undefined ? newArray[0] :this.state.signName} onChange={this.getvalue} />
					
                    {errorName ? <p className="alert alert-danger"> { this.state.errorName }</p> : null}
                </div>
                <div className="form-group">
                    <TextField id="filled-hidden-label-small" label="Title" name="signTitle" variant="outlined" size="small" value={newArray !== undefined ? newArray[1]:this.state.signTitle} onChange={this.getvalue} />
                </div>
                <div className="form-group">
                    <TextField id="filled-hidden-label-small" label="ID or Contact info" name="signCont" variant="outlined" size="small" value={newArray !== undefined ? newArray[2]:this.state.signCont} onChange={this.getvalue} />
                </div>
                <div className="form-group">
                    <Button className="sig-save-btn" variant="contained" onClick={this.handleSave} ><i className="fa fa-save"></i>&nbsp;Save and Date</Button>
					<TextField id={"signDate"+id} className="sig-date-field" label="Date and Time" name="signDate" variant="outlined" size="small" placeholder="fills date and time dd/mm/yyyy hhmmss" value={newArray !== undefined ? newArray[3]+','+ newArray[4] :this.state.signDate} onClick={this.getvalue}/>
                </div>
            </div>
        );

    }
	
}

/* import React from 'react'; 
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export function FieldSig(rowData) {
	
	let id = rowData.fieldId;
	
	return (
		<div className="sig-horizontal" id={"sig"+id} style={{display:'none'}}>    
			<div className="form-group">
				<TextField id="filled-hidden-label-small" label="Name*" name="signName" variant="outlined" size="small" />
			</div>
			<div className="form-group">
				<TextField id="filled-hidden-label-small" label="Title" name="title" variant="outlined" size="small" />
			</div>
			<div className="form-group">
				<TextField id="filled-hidden-label-small" label="ID or Contact info" name="signCont" variant="outlined" size="small" />
			</div>
			<div className="form-group">
				<Button className="sig-save-btn" variant="contained"><i className="fa fa-save"></i>&nbsp;Save and Date</Button>
				<TextField className="sig-date-field" id="filled-hidden-label-small" label="Date and Time" name="signDate" variant="outlined" size="small" placeholder="fills date and time dd/mm/yyyy hhmmss" />
			</div>
		</div>
	);
} */

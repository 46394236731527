import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


export function BasicGridTable(rowData) {
	
	let id = rowData.fieldId;
	let options = rowData.labels;
	let validation = rowData.validation.split(',');
	
	let value = '01/01/2022';
	const handleChange = (newValue) => {
		value = newValue;
	};
	
	let headers = [];
	let rows = [];
	if(options){    
		let str = options.split('_');
		headers = str[0].split(',');
		rows = str[1].split(',');
	}
	let headerHtml = '';
	if(headers){
		headerHtml = headers.map(function(headerText,i) {
			return (	
			<TableCell align="center">
				{headerText}
			</TableCell>	
			);
		})
	}
	let optionsHtml = '';
	if(rows){
		optionsHtml = rows.map(function(rowText,i) {
			let r = i+1;
			return (	
				<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCell component="th" scope="row">
					{rowText}
					</TableCell>
					{
						headers.map(function(headerText,j) {
							let c = j+1;
							//console.log('validation->',validation);
							let str = validation[j].split(':');
							let valiType = str[1];
							//console.log('valiType->',valiType);
							if(valiType == 'V9' || valiType == 'V10' || valiType == 'V11' || valiType == 'V12'){
								return(
								<TableCell align="right">
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<Stack spacing={3}>
										<DesktopDatePicker
										  label=""
										  inputFormat="MM/dd/yyyy"
										  value={value}
										  onChange={handleChange}
										  renderInput={(params) => <TextField {...params} />}
										/>
									</Stack>
								</LocalizationProvider>
								</TableCell>);
							}else{
								return(	
								<TableCell align="right">
									<TextField focused={false} hiddenLabel id="filled-hidden-label-small" label="" variant="outlined" size="small" />
								</TableCell>);
							}
						})
					}
				</TableRow>
			);
		}) 
	}

	return (
		<TableContainer component={Paper}>
		  <Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
			<TableHead>
			  <TableRow>
				<TableCell></TableCell>
				{headerHtml}
			  </TableRow>
			</TableHead>
			<TableBody>
				{optionsHtml}
			</TableBody>
		  </Table>
		</TableContainer>
	);
}

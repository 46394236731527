import React from 'react'; 

export default function FieldCalculation(props) {
	
	const fieldId = props.fieldId;
	const question = props.question;
	
	return (
	<div className="full-width default-div">
		<div className="row">
			<div className="col-md-4 cal-res-box">
				<div className={"cal-res cal-res-"+fieldId }></div>
				<div className="user-text">
					{question }
				</div>
			</div>
		</div>
	</div>)
}

import React, { Component } from 'react'; 
import Box from '@mui/material/Box';
import $ from 'jquery';

export default class Q4 extends Component {
	
	constructor(props) {
		super(props);
        this.shoot = this.shoot.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
        
    shoot(event) {
        this.setState({[event.target.name]:event.target.value})
        this.props.func(this.props.data.fieldId,event.target.value)
    }
	
	handleChange(event){
		let boxId = event.target.id;
		console.log('boxId->',boxId)
		let id = this.props.data.fieldId;
		if ($('#'+boxId).is(':checked')) {
			$('.q4-radio-'+id).each(function () {
				let getId = $(this).attr('id');
				if(getId != boxId){
					$(this).prop('checked', false);
				}
			});
		}
	};
	
    render(){
        const {data} = this.props;
        let id = data.fieldId;
		let options = data.options.split(',');
		let kioskResponse = data.kioskResponse;
		let style = data.arraignment;
        var ts = [];
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
        
		let optionsHtml = '';
        if(options){
			let that=this;
            optionsHtml = options.map(function(val,i) {
                var name = 'action'+id;
                if(ts.indexOf(val) !== -1){
					if(style == 'standard'){
						return (<Box key={i}><input className="cus-radio-btn" type="radio" checked="checked" value={val} name={name}/> {val}</Box>); 
					}else{
						return (<Box key={i}><input id={id+'-'+i} type="checkbox" checked="checked" value={val} name={name} onChange={that.handleChange} className={"cus-radio-btn q4-radio-"+id}/> {val}</Box>);
					}
                }else{
                    if(style == 'standard'){
						return (<Box key={i}><input className="cus-radio-btn" type="radio" name={name} value={val}/> {val}</Box>); 
					}else{
						return (<Box key={i}><input id={id+'-'+i} type="checkbox" name={name}  value={val} onChange={that.handleChange} className={"cus-radio-btn q4-radio-"+id}/> {val}</Box>);
					}
                }
            })
        }
		
        return (
            <div className="ver-radio" onChange={this.shoot}>
               {optionsHtml}
            </div>
        );
    }
}

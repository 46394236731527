import React, { Fragment, useState } from 'react';
import $ from 'jquery';
import config from "../../config";
import axios  from 'axios';
import { makeStyles }   from '@material-ui/core/styles';
import TextField        from '@material-ui/core/TextField';
import Button           from '@material-ui/core/Button';
import Grid             from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: '10px 0px'
    },
    fileGrid: {
        border: '1px solid #c5c5c5',
        margin: '10px -10px',
    },
    inputFile: {
        marginTop: '20px',
    },
    uploadButton: {
        borderRadius: '20px',
    },
    spinner: {
        marginLeft: '15px'
    }
}));

export default function FieldVideo(props) {
	const fieldId = props.data.fieldId;
	const classes = useStyles();
    const [title, setTitle]             = useState('');
    const [description, setDescription] = useState('');
    const [fileName, setFileName]       = useState('');
    const [videoFile, setVideoFile]     = useState('');
    const [embedCode, setEmbedCode]         = useState('');
    const [showSpinner, setShowSpinner]     = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(true);
    const [showUploadButton, setShowUploadButton] = useState(false);
	const [showEmbedCode, setShowEmbedCode] = useState(false);
	const [dataField, setDataField] = useState(props.dataField);
	
	if(!dataField && props.dataField){
		setDataField(props.dataField);
		let str = props.dataField.split(',');
		let emCode = str[0];
		
		let strData = emCode+'<br><b>Name:</b> '+str[1]+'<br><b>Description:</b> '+str[2]+'<br><b>Date:</b> '+str[3];
		$("#ans"+fieldId).html(strData);
	}
	
	const handleChange = (files) => {
        setFileName(files[0].name);
        setVideoFile(files[0]);
    }

	const showUpload = () => {
		setUploadSuccess(false);
		setShowUploadButton(true);
	}
	
	const deleteUpload = () => {
		$( "#ans"+fieldId ).html('');
		setDataField('');
	}
	
	const uploadSubmit = () => {
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"}).replace(',','');
		
		if(videoFile){
			setShowSpinner(true);
			let ApiUrl = $('#ApiUrl').val();
			const client = config.client;
			const secret = config.secret;
			const access = config.access;
			
			let formData = new FormData();
        
			formData.append('video_file', videoFile);
			formData.append('title', title);
			formData.append('description', description);
			
			formData.append('client_id', client);
			formData.append('client_secret', secret);
			formData.append('access_token', access);
			
			axios({
				method: 'POST',
				url: ApiUrl + 'vimeo/public/v1/upload',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				
				let data = response.data;
				if (data.success) {
					setUploadSuccess(true);
					setShowEmbedCode(true);
					setShowUploadButton(false);
					setShowSpinner(false);
					
					let embedCode = data.embed_code;
					let videoData = embedCode+'<br><b>Name:</b> '+title+'<br><b>Description:</b> '+description+'<br><b>Date:</b> '+date;
					$( "#ans"+fieldId ).html(videoData);
					
					let res = embedCode+','+title+','+description+','+date;
					
					props.func(fieldId,res);
					
					setTitle('');
					setDescription('');
					setFileName('');
					setVideoFile('');
					
					setDataField(videoData);
					setEmbedCode(embedCode);
				} else {
					alert('Video upload Failed! Please try again.');
				}
			})
			.catch(error => {
				setShowSpinner(false);
				console.log('error::', error);
			})
			
			
		}else{
			alert('You have to select a video.');
		}
		
		
		/* let blobURL = URL.createObjectURL(videoFile);
		let embedCode = '<iframe src="'+blobURL+'" width="560" height="315" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Inline Video"></iframe>'
		let videoData1 = embedCode+'<br><b>Name:</b> '+title+'<br><b>Description:</b> '+description+'<br><b>Date:</b> '+date;
		$( "#ans"+fieldId ).html(videoData1);
		
		if (blobURL) {
            setUploadSuccess(true);
			setShowEmbedCode(true);
			setShowUploadButton(false);
			setShowSpinner(false);
			setTitle('');
			setDescription('');
			setFileName('');
			setVideoFile('');
			setDataField(embedCode);
		} */
    }
	
	return (
	<Fragment>
		<div id={'ans'+fieldId}></div>
		<form className={classes.root +' upload-btn-type'} noValidate autoComplete="off">
			{ !showUploadButton && <div><Button id={"uploadbtn-"+fieldId} variant="contained" color="primary" onClick={() =>showUpload()}>{dataField ? 'Replace Video' : 'Upload Video'}</Button>&nbsp;{dataField ? <button id={"deletebtn-"+fieldId} type="button" style={{color:'red'}} className="btn code-dialog btn-delete " onClick={() =>deleteUpload()}><i className="fa fa-trash"></i></button> : null}</div>
			}
			{ !uploadSuccess && <Grid container spacing={3} className={classes.fileGrid}>
				<Grid item xs={12} className={classes.inputFile}>
					<Button
						variant="contained"
						component="label"
						color="secondary"
						className={classes.uploadButton}
						>
						<AddIcon /> Select Video File
						<input
							type="file"
							name="video_file" 
							style={{ display: "none" }}
							onChange={ (e) => handleChange(e.target.files) }
						/>
					</Button>
					{ fileName && (<Box component="span" display="block">{fileName}</Box>) }
				</Grid>
				<Grid item xs={12}>
					<TextField id="title" label="Title" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} />
				</Grid>
				<Grid item xs={12}>
					<TextField id="description" label="Description" variant="outlined" value={description} onChange={(e) => setDescription(e.target.value)} />
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" onClick={() =>uploadSubmit()}>Upload { showSpinner && <CircularProgress disableShrink size="20px" className={classes.spinner} /> }</Button>
				</Grid>
			</Grid> }
		</form>
	</Fragment>
	);
}

import React, { Component } from 'react'; 
import Box from '@mui/material/Box';
import $ from 'jquery';
import Checkbox from '@mui/material/Checkbox';

export default class Q11 extends Component {
	
	constructor(props) {
    super(props);
        this.state = {itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        const item = event.target.name;
        const isChecked = event.target.checked;
        const value =event.target.value;
        let itemArray = this.state.itemArray
        let sh = {name:item, value:value, isChecked:isChecked};

        if(isChecked==true){
            itemArray.push(value)
        }else{
            if(itemArray.indexOf(value) !==-1){
                itemArray = itemArray.filter(e => e !== value)
            }
        }
		let itemString = itemArray.toString();
		this.setState({itemArray});
		this.props.func(this.props.data.fieldId,itemString);
    }
	
    render(){
        const {data} = this.props;
        let id = data.fieldId;
		let options = data.options.split(',');
		let kioskResponse = data.kioskResponse;
		
        let ts = '';
        if(kioskResponse.size > 0){
            let resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
		
        let optionsHtml = '';
        if(options){
            optionsHtml = options.map(function(val,i) {
				let name = 'action'+i;
				if(ts.indexOf(val) !== -1){
					return (<Box key={i}><Checkbox value={val} checked={true} name={name} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} /> {val}</Box>);  
				}else{
					return (<Box key={i}><Checkbox value={val} checked={false} name={name} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} /> {val}</Box>);
                }
            })
        }
       
		return (<div onChange={this.shoot} className="ver-checkbox">
		   {optionsHtml}
		</div>);
    }
}

import React, { Component } from 'react';
import $ from 'jquery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';

export default class LikertTable extends Component {
	
	constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        let name = event.target.name;
		let id = this.props.data.fieldId;
		name = name.replace(id,'');
        let value = event.target.value;
		let itemArray = this.state.itemArray
		itemArray[name] = name+':'+value;
		let newItemArray = []
		Object.keys(itemArray).map(function(key) {
           newItemArray.push(itemArray[key]);
        });
        let itemString = newItemArray.toString();
		
        this.props.func(this.props.data.fieldId,itemString);
    }
	
    render(){
        const {data} = this.props;
        let id = data.fieldId;
		let options = data.labels;
		let kioskResponse = data.kioskResponse;
		
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split(',');
            }
        }
		//console.log('kioskResponse->',kioskResponse);
		let headers = [];
		let rows = [];
		if(options){    
			let str = options.split('_');
			headers = str[0].split(',');
			rows = str[1].split(',');
		}
		
		let optionsHtml = '';

        if(rows){
			
			let point = [1,2,3,4,5,6,7,8,9,10];
			
			optionsHtml = rows.map(function(rowText,i) {
				return (	
					<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell align="center">
						{rowText}
						</TableCell>
						{
							point.map(function(val,j) {
								if(ts.indexOf(rowText+':'+val) !== -1){
									return(	
									<TableCell align="center" key={j}>
										<input type="radio" value={val}  name={id+''+rowText}  checked={true}/>
									</TableCell>);
								}else{
									return(	
									<TableCell align="center" key={j}>
										<input type="radio" value={val}  name={id+''+rowText}  checked={false}/>
									</TableCell>);
								}
							})
						}
					</TableRow>
				);
			})
        }
		
		return (
			<TableContainer component={Paper}  onChange={this.shoot}>
			  <Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
				<TableHead>
				  <TableRow>
					<TableCell></TableCell>
					<TableCell colSpan={7}>{headers[0]}</TableCell>
					<TableCell colSpan={3} align="right">{headers[1]}</TableCell>
					
				  </TableRow>
				</TableHead>
				<TableBody>
					{optionsHtml}
				</TableBody>
			  </Table>
			</TableContainer>
		); 
    }
}

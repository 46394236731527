import React, { Component } from 'react'; 
import $ from 'jquery';
import Checkbox from '@mui/material/Checkbox';

export default class Q5 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			q5action:false   
		}
		this.shoot = this.shoot.bind(this); 
	}
	
	shoot(event) {
		var isChecked= event.target.checked;
		var value = '';
		if(isChecked == true){
			value = 'checked';
		}else{
			value = 'not-checked';
		}
		this.setState({q5action:isChecked});
		this.props.func(this.props.data.fieldId,value);
	}
	
	render(){
		const {data} = this.props;
        let id = data.fieldId;
		let kioskResponse = data.kioskResponse;
		
		return (<Checkbox name={id} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} checked={kioskResponse.get(id) == 'checked' ? true : false} onChange={this.shoot}/>);
    }
}

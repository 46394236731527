import React, { Component } from 'react';
import Box from '@mui/material/Box';
import $ from 'jquery';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DataTable from 'react-data-table-component';

export default class StatusTracker extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			trackerData:[],
			filteredPerson:[],
			asc:0,
			desc:0,
			tableUpdate:false,
		}
    }
	
	componentWillReceiveProps() {
		if(this.props.trackerTableUpdate){
			this.setState({trackerData:[],trackerFormFilter:'',trackerRoleFilter:'',trackerPersonFilter:'',trackerFacilityFilter:'',tableUpdate:true});
		}
	}
	
	/* setFacilityFilter=(event)=>{
		$("#statusFilter option:selected").prop("selected", false);
		let item = event.target.name;
		let value = event.target.value;
		$('.status-row').addClass('hide-row');	
		if(value){
			$('.'+value).show();
			$('.'+value).removeClass('hide-row');
		}else{
			$('.status-row').show();
			$('.status-row').removeClass('hide-row');
		};
	} */
	
	setStatusFilter=(event)=>{
		let item = event.target.name;
		let value = event.target.value.replace(/ /g, '');
		if(value == 'In-Progress'){
			$('.status-row').show();
			$('.Complete-Hidden').hide();
			//$('.'+value).show();
		}else if(value == 'Complete-Hidden'){
			$('.status-row').hide();
			$('.'+value).show();
		}else if(value == 'Cancelled-Hidden'){
			$('.status-row').hide();
			$('.'+value).show();
		}else{
			$('.status-row').show();
		};
	}
	
	showNote=(formId)=>{
		$('#'+formId).modal('show');
	}
	
	sortStartDate=()=>{
		let data = [];
		if(this.state.trackerData.length > 0 || this.state.trackerFormFilter || this.state.trackerRoleFilter || this.state.trackerPersonFilter){
			data = this.state.trackerData;
		}else{
			data = this.props.data;
		}
		let asc = this.state.asc;
		if(asc == 0){
			data.sort(function(a, b){
				return new Date(b.date) - new Date(a.date);
			});
			this.setState({trackerData:data,asc:1});
		}else{
			data.sort(function(a, b){
				return new Date(a.date) - new Date(b.date);
			});
			this.setState({trackerData:data,asc:0});
		}
		this.setState({tableUpdate:false});
		$("#statusFilter option:selected, #facilityFilter option:selected").prop("selected", false);
	}
	
	sortDueDate=()=>{
		let data = [];
		if(this.state.trackerData.length > 0 || this.state.trackerFormFilter || this.state.trackerRoleFilter || this.state.trackerPersonFilter){
			data = this.state.trackerData;
		}else{
			data = this.props.data;
		}
		
		let desc = this.state.desc;
		if(desc == 0){
			data.sort(function(a, b){
				return new Date(b.due_date) - new Date(a.due_date);
			});
			this.setState({trackerData:data,desc:1});
		}else{
			data.sort(function(a, b){
				return new Date(a.due_date) - new Date(b.due_date);
			});
			this.setState({trackerData:data,desc:0});
		}
		this.setState({tableUpdate:false});
		$("#statusFilter option:selected, #facilityFilter option:selected").prop("selected", false);
	}
	
	sortRank=()=>{
		let data = [];
		if(this.state.trackerData.length > 0 || this.state.trackerFormFilter || this.state.trackerRoleFilter || this.state.trackerPersonFilter){
			data = this.state.trackerData;
		}else{
			data = this.props.data;
		}
		
		let ascr = this.state.ascr;
		if(ascr == 0){
			data.sort(function(a, b){
				return b.rank - a.rank;
			});
			this.setState({trackerData:data,ascr:1});
		}else{
			data.sort(function(a, b){
				return a.rank - b.rank;
			});
			this.setState({trackerData:data,ascr:0});
		}
		this.setState({tableUpdate:false});
		$("#statusFilter option:selected").prop("selected", false);
		$(".status-row").show();
	}
	
	sortPriority=()=>{
		let data = [];
		if(this.state.trackerData.length > 0 || this.state.trackerFormFilter || this.state.trackerRoleFilter || this.state.trackerPersonFilter){
			data = this.state.trackerData;
		}else{
			data = this.props.data;
		}
		let ascp = this.state.ascp;
		if(ascp == 0){
			data.sort(function(a, b){
				return b.priority - a.priority;
			});
			this.setState({trackerData:data,ascp:1});
		}else{
			data.sort(function(a, b){
				return a.priority - b.priority;
			});
			this.setState({trackerData:data,ascp:0});
		}
		this.setState({tableUpdate:false});
		$("#statusFilter option:selected").prop("selected", false);
	}
	
	setFormFilter=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		let data = this.props.data;
		let filterData = [];
		data.map((row,i) => {
			if(row.form_name == value){
				filterData.push(row);
			}
		})
		this.setState({[name]:value,trackerData:filterData,trackerRoleFilter:'',trackerPersonFilter:'',trackerFacilityFilter:'',tableUpdate:false});
		$("#roleFilter option:selected, #statusFilter option:selected, #facilityFilter option:selected").prop("selected", false);
	}
	
	setRoleFilter=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		let data = this.props.data;
		let trackerFormFilter = this.state.trackerFormFilter;
		let filterData = [];
		let filteredPerson = [];
		if(trackerFormFilter){
			data.map((row,i) => {
				if(row.role == value && row.form_name == trackerFormFilter){
					filterData.push(row);
					filteredPerson.push(row.person);
				}else if(!value && row.form_name == trackerFormFilter){
					filterData.push(row);
					filteredPerson.push(row.person);
				}
			})
		}else{
			data.map((row,i) => {
				if(row.role == value){
					filterData.push(row);
					filteredPerson.push(row.person);
				}
			})
		}
		this.setState({[name]:value,trackerData:filterData,trackerPersonFilter:'',trackerFacilityFilter:'',filteredPerson,tableUpdate:false});
		$("#personFilter option:selected, #statusFilter option:selected, #facilityFilter option:selected").prop("selected", false);
	}
	
	setPersonFilter=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		let data = this.props.data;
		let trackerFormFilter = this.state.trackerFormFilter;
		let trackerRoleFilter = this.state.trackerRoleFilter;
		let filterData = [];
		
		if(trackerFormFilter && trackerRoleFilter){
			data.map((row,i) => {
				if(row.person == value && row.form_name == trackerFormFilter && row.role == trackerRoleFilter){
					filterData.push(row);
				}else if(!value && row.form_name == trackerFormFilter && row.role == trackerRoleFilter){
					filterData.push(row);
				}
			})
		}else if(trackerFormFilter && !trackerRoleFilter){
			data.map((row,i) => {
				if(row.person == value && row.form_name == trackerFormFilter){
					filterData.push(row);
				}else if(!value && row.form_name == trackerFormFilter){
					filterData.push(row);
				}
			})
		}else if(!trackerFormFilter && trackerRoleFilter){
			data.map((row,i) => {
				if(row.person == value && row.role == trackerRoleFilter){
					filterData.push(row);
				}else if(!value && row.role == trackerRoleFilter){
					filterData.push(row);
				}
			})
		}else{
			data.map((row,i) => {
				if(row.person == value){
					filterData.push(row);
				}
			})
		}
		this.setState({[name]:value,trackerData:filterData,tableUpdate:false,trackerFacilityFilter:''});
		$("#statusFilter option:selected, #facilityFilter option:selected").prop("selected", false);
	}

	setFacilityFilter=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		let data = this.props.data;
		let trackerFormFilter = this.state.trackerFormFilter;
		let trackerRoleFilter = this.state.trackerRoleFilter;
		let trackerPersonFilter = this.state.trackerPersonFilter;
		let filterData = [];
		
		if(trackerFormFilter && trackerRoleFilter && trackerPersonFilter){
			data.map((row,i) => {
				if(row.facilityID == value && row.form_name == trackerFormFilter && row.role == trackerRoleFilter && row.person == trackerPersonFilter){
					filterData.push(row);
				}else if(!value && row.form_name == trackerFormFilter && row.role == trackerRoleFilter && row.person == trackerPersonFilter){
					filterData.push(row);
				}
			})
		}else if(trackerFormFilter && trackerRoleFilter && !trackerPersonFilter){
			data.map((row,i) => {
				if(row.facilityID == value && row.form_name == trackerFormFilter && row.role == trackerRoleFilter){
					filterData.push(row);
				}else if(!value && row.form_name == trackerFormFilter && row.role == trackerRoleFilter){
					filterData.push(row);
				}
			})
		}else if(trackerFormFilter && !trackerRoleFilter && !trackerPersonFilter){
			data.map((row,i) => {
				if(row.facilityID == value && row.form_name == trackerFormFilter){
					filterData.push(row);
				}else if(!value && row.form_name == trackerFormFilter){
					filterData.push(row);
				}
			})
		}else if(!trackerFormFilter && trackerRoleFilter && trackerPersonFilter){
			data.map((row,i) => {
				if(row.facilityID == value && row.role == trackerRoleFilter && row.person == trackerPersonFilter){
					filterData.push(row);
				}else if(!value && row.role == trackerRoleFilter && row.person == trackerPersonFilter){
					filterData.push(row);
				}
			})
		}else if(!trackerFormFilter && trackerRoleFilter && !trackerPersonFilter){
			data.map((row,i) => {
				if(row.facilityID == value && row.role == trackerRoleFilter){
					filterData.push(row);
				}else if(!value && row.role == trackerRoleFilter){
					filterData.push(row);
				}
			})
		}else if(!trackerFormFilter && !trackerRoleFilter && trackerPersonFilter){
			data.map((row,i) => {
				if(row.facilityID == value && row.person == trackerPersonFilter){
					filterData.push(row);
				}else if(!value && row.person == trackerPersonFilter){
					filterData.push(row);
				}
			})
		}else{
			data.map((row,i) => {
				if(row.facilityID == value){
					filterData.push(row);
				}
			})
		}
		this.setState({[name]:value,trackerData:filterData});
		$("#statusFilter option:selected").prop("selected", false);
	}
	
	render() {
		const {data,editTracker,showTrackerView,trackerViewPdf,statusType,facilityIds} = this.props;
		
		let facilityOption = facilityIds.map(function(val,i) {
			let facility = val.split('-');
			return (
				<option value={facility[1]} key={i}>{facility[0]}</option>
			);
		})
		
		let formName = [];
		let roleName = [];
		let personName = [];
		
		data.map(function(row,i) {
			formName.push(row.form_name);
			roleName.push(row.role);
			personName.push(row.person);
		})
		
		if(this.state.filteredPerson.length > 0){
			personName = this.state.filteredPerson;
		}
		
		let formNames = formName.filter(function(elem, index, self) {
			return index === self.indexOf(elem);
		});
		
		let roleNames = roleName.filter(function(elem, index, self) {
			return index === self.indexOf(elem);
		});
		
		let personNames = personName.filter(function(elem, index, self) {
			return index === self.indexOf(elem);
		});
		
		let formFilterOption = formNames.map(function(row,i) {
			return (
				<option value={row} key={i}>{row}</option>
			);
		})
		
		let roleFilterOption = roleNames.map(function(row,i) {
			return (
				<option value={row} key={i}>{row}</option>
			);
		})
		
		let personFilterOption = personNames.map(function(row,i) {
			return (
				<option value={row} key={i}>{row}</option>
			);
		})
		
		let that = this;
		let rows = [];
		if(!this.state.tableUpdate && (this.state.trackerData.length > 0 || this.state.trackerFormFilter || this.state.trackerRoleFilter || this.state.trackerPersonFilter || this.state.trackerFacilityFilter)){
			rows = this.state.trackerData;
		}else{
			rows = data;
		}
		
		let rowHtml = '';
		let modals = '';

		if(rows){
			
			rowHtml = rows.map(function(row,i) {
				let status = row.status.replace(/ /g, '');
				let facilityName = '';
				facilityIds.map(function(val,i) {
					let str = val.split('-');
					let fId = str[1];
					if(fId == row.facilityID){
						facilityName = str[0];
					}
				});
				
				let priority ='Normal';
				if(row.priority == 1){
					priority ='Low';
				}else if(row.priority == 3){
					priority ='High';
				}
				
				return (<tr className={'status-row '+status+' '+row.facilityID}>
					<td>{moment(row.date).format('MM/DD/YYYY')}</td>
					<td>{moment(row.due_date).format('MM/DD/YYYY')}</td>
					<td>{row.form_name}</td>
					<td>{row.role}</td>
					<td>{row.person}</td>
					<td>{row.status}</td>
					<td>{facilityName}</td>
					<td>{priority}</td>
					<td>{row.rank}</td>
					<td><button className="btn btn-modal-tracker" onClick={()=>that.showNote(row.form_id)}><i className="fa fa-sticky-note-o" aria-hidden="true"></i></button></td>
					<td><button id={'btn-eye-'+row.form_id} type="button" onClick={()=>showTrackerView(row.form_name,row.form_id,row)} className="btn btn-eye-tracker"><i className="fa fa-eye"></i></button></td>
					<td><button id={'btn'} type="button" onClick ={()=>editTracker(row)} className="btn btn-edit-tracker"><i className="fa fa-edit"></i></button></td>
					<td><button type="button" onClick={()=>trackerViewPdf(row.form_name,row.form_id,row)} className="btn mb-2"> <i className="fa fa-file-pdf" aria-hidden="true"></i></button></td>
				</tr>);
			})
			
			modals = data.map(function(row,i) {
				let regX = /(<([^>]+)>)/ig;
				let note = row.note.replace(regX, "");
				return (<div className="modal" id={row.form_id}>
					<div className="modal-dialog modal-lg modal-sm tracker-modal">
						<div className="modal-content">
							<div className="modal-body">
							{note}
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				);
			})
				
        }
		return (
			<Box>
				<div className="find-status-filter">
					<b>View Status For:</b>
					<select id="statusFilter" className="form-control filter-form" onChange={this.setStatusFilter}>
						<option value="">All</option>
						<option value="In-Progress">In-Progress</option>
						<option value="Complete-Hidden">Complete-Hidden</option>
						<option value="Cancelled-Hidden">Cancelled-Hidden</option>
					</select>	
				</div>
				<div className="find-facility-filter">
					<b>Facility:</b>
					<select id="facilityFilter" className="form-control filter-form" name="trackerFacilityFilter" value={this.state.trackerFacilityFilter} onChange={this.setFacilityFilter}>
						<option value="">All</option>
						{facilityOption}
					</select>	
				</div>
				
				<div className="find-person-filter">
					<b>Person:</b>
					<select id="personFilter" className="form-control filter-form" name="trackerPersonFilter" value={this.state.trackerPersonFilter} onChange={this.setPersonFilter}>
						<option value="">All</option>
						{personFilterOption}
					</select>	
				</div>
				
				<div className="find-role-filter">
					<b>Role:</b>
					<select id="roleFilter" className="form-control filter-form" name="trackerRoleFilter" value={this.state.trackerRoleFilter} onChange={this.setRoleFilter}>
						<option value="">All</option>
						{roleFilterOption}
					</select>	
				</div>
				
				<div className="find-form-filter">
					<b>Form Name:</b>
					<select className="form-control filter-form" name="trackerFormFilter" value={this.state.trackerFormFilter} onChange={this.setFormFilter}>
						<option value="">All</option>
						{formFilterOption}
					</select>	
				</div>
				
				<table className="table table-bordered tracker-table">
				<tr>
					<th className="k-sort" onClick={this.sortStartDate}>Start Date <i className="fa fa-sort"></i></th>
					<th className="k-sort" onClick={this.sortDueDate}>Due Date <i className="fa fa-sort"></i></th>
					<th>Form Name</th>
					<th>Responsible Role</th>
					<th>Responsible Person</th>
					<th>Status</th>
					<th>Facility</th>
					<th className="k-sort" onClick={this.sortPriority}>Priority <i className="fa fa-sort"></i></th>
					<th className="k-sort" onClick={this.sortRank}>Rank <i className="fa fa-sort"></i></th>
					<th>Note</th>
					<th style={{width: '85px'}}>View Form</th>
					<th>Edit</th>
					<th>PDF</th>
				</tr>
				{rowHtml}
				</table>
				{modals}
			</Box>
		);
	}
}

import React, { Component } from 'react';
import 'bootstrap';
import $ from 'jquery';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

export default class Checklist extends Component {
	constructor(props){
        super(props)
        this.state = {
			options:[],
        }
    }
	
	addOption=(fieldId,fieldGuid)=>{
		let val = $('#option-'+fieldId).val();
		let options = this.state.options;
		options.push(val);
		this.setState({options});
		$('#option-'+fieldId).val('');
	}
	
	deleteOption=(fieldId,val)=>{
		let options = this.state.options;
		let newOptions = [];
		$('#option-'+fieldId).val(val);
		options.map(function(str,i) {
			if(str != val){
				newOptions.push(str);
			}
		});
		this.setState({options:newOptions});
	}
	
	render() {
		const {fieldId, fieldGuid, question, NoteHTML, NoteModal, outputText, required} = this.props;
		
		let optionsHtml = '';
		let options = this.state.options;
		let that = this;
		if(options){
			optionsHtml = options.map(function(val,i) {
				let name = 'action'+i;
				return (
					<Box><Checkbox name={name} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} /> {val} <button type="button" className="btn code-dialog" onClick={() =>that.deleteOption(fieldId,val)} ><i className="fa fa-edit"></i></button></Box>
				);
			})
		}
		
		return (<div className="full-width default-div">
			<div className="row">
				<div className={'col-md-10 input-text'}>
					{ question } { required == 'Y' ? <span className="required">*</span> : ''}
				</div>
				<div className={'col-md-2'}>
					{NoteHTML} {NoteModal}
				</div>
			</div>
			<div className="row">
				<div className={"col-md-12 answer"} >
					{!outputText ?
					<div className="input-check-list">
						<TextField focused={false} hiddenLabel id={'option-'+fieldId} label="" variant="outlined" size="small" />
					</div>
					:null}
					{!outputText ?
					<div className="plus-check-list">
						<button type="button" className="btn btn-primary input-group-text" style={{ backgroundColor:'blue',color:'white'}} onClick={() =>this.addOption(fieldId,fieldGuid)}>
							<span className="glyphicon glyphicon-plus"> + </span>
						</button>
					</div>
					:null}
					<div className="ver-checkbox-2">
					   {optionsHtml}
					</div>
				</div>   
			</div>
		</div>);
	}
}

import React, { Component } from 'react';
import Box from '@mui/material/Box';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import arrayMove from "./arrayMove";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';


export default class StatusManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			statusData:[],
		}
    }
	
	componentDidMount() {
		this.getTrackerData();
	}
	
	getTrackerData=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'tracker/get_status.php';
		let data = [];
		let con = {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'content-type': 'multipart/form-data'
                }
            }
        axios.get(url,con)
        .then(response => {
            data = response.data;
			this.setState({statusData:data});
        })
	}
	
	addTrackerStatus=()=>{
		let val = $('#statusText').val();
		let ApiUrl = $('#ApiUrl').val();
		
		if(val){
			let url = ApiUrl+'tracker/add_status.php';
			let formData = new FormData();
			formData.append('status', val);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getTrackerData();
					alert(response.data);
					$('#statusText').val('');
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	updateTrackerStatus=(event)=>{
		
        let name = event.target.name;
        let id = event.target.value;
		let visible = 'N';
		if($("input[name='"+name+"']").prop("checked") == true){
			visible = 'Y';
			
		}
		this.setState({ [name]: visible });
		let that = this;
		if(id && visible){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'tracker/update_status.php';
			let formData = new FormData();
			formData.append('id', id);
			formData.append('visible', visible);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					//alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteStatus=(id)=>{
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'tracker/delete_status.php';
			let formData = new FormData();
			formData.append('id', id);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				this.getTrackerData();
				if(response.data){
					//alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	onSortEnd = ({oldIndex, newIndex}) => {
		this.setState(({statusData}) => ({
		    statusData: arrayMove(statusData, oldIndex, newIndex),
		}));

		let newArray = [];
		this.state.statusData.map((val, i) => {
			let j = i+1;
			newArray[i] = {'id':val.id,'pos':j};
		})
		
		let that = this;
		if(newArray.length > 0){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'tracker/update_pos.php';
			axios({
				method: 'POST',
				url: url,
				data: newArray,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					//alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	};
	
	render() {
		const {statusData} = this.state;

		let that = this;
		const DragHandle = sortableHandle(() => <span className="showcase"></span>);
		const SortableItem = sortableElement(({value}) => {
			
			let str = 'status-'+value.id;
			let checked = value.visible == 'Y' ? true:false;
			if(that.state[str]){
				checked = that.state[str] == 'Y' ? true:false;
			}
			
			return (<div id={value.id} className={'field-div'}><DragHandle /><span className="input-title">{value.status}</span>
			<div className="edit-btns-status"><input name={'status-'+value.id} type="checkbox" value={value.id} checked={checked ? 'checked' : ''} onChange={that.updateTrackerStatus} /><button type="button" style={{color:'red'}} className="btn code-dialog btn-delete" onClick={() => { if (window.confirm('Are you sure you want to Delete this status?')) that.deleteStatus(value.id)}}><i className="fa fa-trash"></i></button></div>
			</div>);
		});
		
		const SortableContainer = sortableContainer(({children}) => {
		  return <div className="status-inner">{children}</div>;
		});
		
		return (
			<div className="status-list">
				<h4>Status List</h4>
				<div className="status-tracker-top"><label> Add New Status: </label><input id="statusText" className="form-control" name="fabCompleted" type="text" /><button type="button" className="btn btn-info btn-save-status" onClick={this.addTrackerStatus}>Save</button></div>
				{ statusData ? 
				<div className="status-table-div">
				<SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
					{statusData.map((value, index) => (
					  <SortableItem key={index} index={index} value={value} /> 
					))}
				</SortableContainer>
				</div>
				:null}
			</div>	
		);
	}
}

import React from 'react'; 

export default function FieldInlineImage(props) {
	
	const fieldId = props.data.fieldId;
	const data = props.data.dataLink;
	
	return (<div>
		{data ?
			<img className="in-image file-upload-image" src={data} />
		:null}
	</div>);
}

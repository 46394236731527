import React, { Component } from 'react';
import Box from '@mui/material/Box';
import $ from 'jquery';

export default class Q2 extends Component {
	
	constructor(props){
        super(props)
		this.state = {
            users: '',
            newoption:'',
            userOption:'',
        }
        this.shoot = this.shoot.bind(this); 
        this.saveOption = this.saveOption.bind(this);
		this.userInput = this.userInput.bind(this);
        this.addInput = this.addInput.bind(this);		
    }
	
	shoot(event) {
        this.props.func(this.props.data.fieldId,event.target.value);
		this.setState({newoption: ''});
    }
	
	addUser = () => {
		let id = this.props.data.fieldId;
		$('#addOpt-'+id).show();
    }
	
	userInput(event){
		this.setState({userOption: event.target.value});
		this.setState({newoption: ''});
    }
	
	addInput(event){
        event.preventDefault();
        this.saveOption(event,this.state.userOption);
        this.setState({userOption: ''});
    }
	
	saveOption(event,newoption){
        //this.saveOptionData(newoption)
        this.setState({newoption:newoption});
        event.preventDefault();  
    }

	render() {
		const {data} = this.props;
		const {newoption} = this.state;
		//console.log('newoption->',newoption);
		let id = data.fieldId;
		if(newoption){
            $('#select-'+id).append($("<option></option>").attr("value",newoption).text(newoption));
        }
		
		let options = data.options.split(',');
		let kioskResponse = data.kioskResponse;
		//console.log('kioskResponse->',kioskResponse);
		
		let optionsHtml = '';
		if(options){
			optionsHtml = options.map(function(val,i) {
				return (
					<option value={val} key={i}> {val} </option>
				);
			})
		}
		
		return (
			<Box>
			<select className="form-control type-q2" id={"select-"+id} value={kioskResponse.size > 0 ? kioskResponse.get(id) : ''} onChange={this.shoot}>
				<option value=""> Please Select</option>
				{optionsHtml}
			</select>
			<div className="input-group-append">
			<button type="button" className="btn btn-primary input-group-text" style={{ backgroundColor:'blue',color:'white'}} onClick={this.addUser}>
				<span className="glyphicon glyphicon-plus"> + </span>
			</button>
			</div>
			<div id={"addOpt-"+id} className="input-group mb-3 add-opt-q2" style={{display:'none'}}>
                <input type="text" className="form-control" value={this.state.userOption} onChange={this.userInput}/>
                <div className="input-group-append">
                <button className="btn btn-primary input-group-text" onClick={this.addInput}>Add</button>
                </div>
            </div>
			</Box>
		);
	}
}

import React, { Component, PropTypes } from 'react'
import 'bootstrap';
import $ from 'jquery';
import config from "../../config";
import { gapi } from 'gapi-script';
import { Form,Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { generatehtmlfromlocation } from "../../Helpers/Common";
import Checkbox from '@mui/material/Checkbox';

import FieldQ13 from "../Fields/Q13.js";
import Launchpad from "../Fields/Launchpad";
//import FieldInlineImage from "../Fields/InlineImage.js";
import FieldInlineVideo from "../Fields/InlineVideo.js";
import FieldPdfApi from "../Fields/PdfApi.js";
import FieldAssessment from "../Fields/Assessment.js";
import FieldSubjective from "../Fields/Subjective.js";
import FieldCalculation from "../Fields/Calculation.js";
import Checklist from "../Fields/Checklist";

import { noteHtml,noteModal,attentHTML} from "../Modal";

export default class MainData extends Component {
    constructor(props){
        super(props)
        this.state = {
            checkedNeeds:new Map(),
            checkedApprove:new Map(),
        }
    }
	
    classTypes(comment){
        let className = 'question';
		let notInclude = '';
        if(comment[5].toLowerCase() == 'parent header'){
            className = 'parent-header';
        }else if(comment[5].toLowerCase() == 'child header 1'){
			className = 'header-1 ';
		}else if(comment[5].toLowerCase() == 'child header 2'){
			className = 'header-2 ';
		}else if(comment[5].toLowerCase() == 'child header 3'){
			className = 'header-3 ';
		}
		
		if(comment[5].toLowerCase() == 'boxbegin' || comment[5].toLowerCase() == 'boxend'){
			className = ' '+comment[5]+'-div ';
		}
		
		if(comment[5] !== 'Parent Header' && comment[14] == 'N'){
            className = className+' not-include';
        }
		let needNote = this.props.needsNote.get(comment[0]);
		if(needNote){
			className = className+' attention';
		}
		
		if(!this.props.showResponseData && !this.props.delegateFilter && comment[14] == 'Y' && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
			$('#'+comment[0]).removeClass("not-include");
		}
		
		if(this.props.delegateFilter && comment[14] == 'Y'){
			let delegateFilter = this.props.delegateFilter;
			let uArray = this.props.delegateUsers.get(comment[0]);
			
			/* if(uArray.indexOf(delegateFilter) == -1 && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
				className = className+' notA';
			} */
			
			if(uArray.indexOf(delegateFilter) == -1 && (comment[5].toLowerCase() != 'parent header' && comment[5].toLowerCase() != 'child header 1' && comment[5].toLowerCase() != 'child header 2' && comment[5].toLowerCase() != 'child header 3')){
				className = className+' not-include';
			}else if(delegateFilter != '' && delegateFilter != 'CPO' && comment[37] == 'N'){
				className = className+' not-include';
			}
			
			setTimeout(function(){
				if(comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3'){
					$('#'+comment[0]).removeClass("not-include");
				}
			
				if($('#'+comment[0]).find('.question').not(".not-include").length < 1 && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
					$('#'+comment[0]).addClass("not-include");	
				}
			}, 2000);
		}
		
		if(this.props.showResponseData){
			setTimeout(function(){
				if(($('#'+comment[0]).find('.question').not(".not-include").length < 1 || $('#'+comment[0]).find('.question').not(".not-response").length < 1) && (comment[5].toLowerCase() == 'parent header' || comment[5].toLowerCase() == 'child header 1' || comment[5].toLowerCase() == 'child header 2' || comment[5].toLowerCase() == 'child header 3')){
					$('#'+comment[0]).addClass("not-include");	
				}
			}, 2000);
		}
		
		return className;
    }
	
	toggle(id){
		if(!$('#parent'+id.toLowerCase()).prop('disabled')){
			$('#parent'+id.toLowerCase()).find('i:first').toggleClass('fa fa-plus-square-o fa-1x mr-2  fa fa-minus-square-o fa-1x mr-2');
		}
		$('#parent'+id.toLowerCase()).prop('disabled', true);
		setTimeout(function(){
			$('#parent'+id.toLowerCase()).prop('disabled', false);
		}, 1000);
    }
	
	showStar(comment){
        let iTag
        this.props.sheetData.map((valu, i) => {
            if(comment[0] === valu[7] &&  comment[7]){
                iTag = <span><i className="fa fa-star" style={{fontSize:'20px',color:'yellow'}} data-toggle="modal" data-target={"#showStarModalYellow"+comment[0]}></i><i className="fa fa-star" style={{fontSize:'20px',color:'blue'}} data-toggle="modal" data-target={"#showStarModalBlue"+comment[0]}></i></span>
            }else if(comment[0] === valu[0] && comment[7] === valu[7] && comment[8]){
                iTag = <i className="fa fa-star" style={{fontSize:'20px',color:'blue'}} data-toggle="modal" data-target={"#showStarModalBlue"+comment[0]} ></i>
            }else if(comment[0] === valu[7]){
                iTag = <i className="fa fa-star" style={{fontSize:'20px',color:'yellow'}} data-toggle="modal" data-target={"#showStarModalYellow"+comment[0]} ></i>
            }
        })
        return iTag
    }
	
	getQueType(type,id,res){
		
        switch(type){
            case 'sig':
                return <i className="fas fa-edit" onClick={()=>this.showSign2(type,id)}></i>
            break;
            case 'q10':
                return <Checkbox id={'type'+id} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} onClick={()=>this.showSign(type,id)} checked={res ? true : false}/>
            break;
            default:
                return null
        }
    }
	
	showSign=(type,id)=>{
		if($("#type"+id).prop("checked") == true){
			$('#'+type+id).css('display','block');
		}else if($("#type"+id).prop("checked") == false){
			$('#'+type+id).css('display','none');
		}
    }
	
	showSign2=(type,id)=>{
        $('#'+type+id).css('display','block')
    }
	
	subQuestions = (id,value) => {
		this.props.getResponseValue(id,value);
	}
	
	showNeedModal=(needIds,note)=>{
        this.setState({[needIds]:''});
		if(this.props.showResponseData){
			this.setState({[needIds]:note});
		}else{
			this.props.allData.map((valu, i) => {
				if(valu[0] === needIds){
					this.setState({[needIds]:valu[27]});
				}
			})
		}
		
        $('#needs'+needIds).modal('show');
    }
	
	handleNeedsNote=(e)=>{
		const item = e.target.name;
        this.setState({[item]:''});
		this.props.handleNeeds(e);
	}
	
	renderActions(fieldId,note){
		let needHTML = '';
		if(this.props.dataMode == 'TestForm'){
			
			if(this.props.showResponseData){
				let needsNote = this.props.needsNote.get(fieldId);
				if(needsNote !== undefined){
					needHTML = attentHTML(fieldId,needsNote,this.showNeedModal);
				}
				
			}else{
				needHTML = attentHTML(fieldId,note,this.showNeedModal);
			}
			
			return(<div className={this.props.showResponseData ? 'needNote needNicon' : 'col-md-1 needNote'}>
				<div className="row">
					<div className="need-icon">{needHTML}</div>
					{!this.props.showResponseData ?
					<div className="need-checkbox"> <input type="checkbox" name ={fieldId} checked={ note ? 'checked' : this.props.checkedNeeds.get(fieldId)} onChange={this.handleNeedsNote}/></div>
					:null}
				</div>      
			</div>);
		}else{
			return null;
		}			
    }
	
	needsModal=(needData, needIds)=>{
        if(typeof needIds !== 'undefined' && needIds !== ''){
            return (<div className="modal" id={'needs'+needIds}>
                <div className="modal-dialog">
                    <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Needs Attention Note <b>{needIds}</b></h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        <textarea rows={this.props.showResponseData ? '5' : '3'} className="form-control" name={ needIds } value={this.state[needIds]} onChange={this.getValue}></textarea>
                    </div>
                    {needData[27] ?
                        <div className="modal-footer need-attention-footer">
                            <button type="button" className="btn btn-primary mb-2 mt-2" onClick={()=>this.props.removeNeedsNote(needIds)}> Remove Need Note</button>
                            <button type="button" className="btn btn-primary"  data-dismiss="modal" onClick={()=>this.props.saveNeedsNote(needIds,this.state[needIds])} style={{textAlign:'left'}}>Save</button>
                            <button type="button" className="btn btn-danger"  data-dismiss="modal">Close</button>
                        </div>
                    :
                        <div className="modal-footer need-attention-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>this.props.saveNeedsNote(needIds,this.state[needIds])} style={{textAlign:'left'}}>Save</button>
                            <button type="button" className="btn btn-danger" onClick={()=>this.props.saveNeedsNote(needIds,'')}  data-dismiss="modal">Close</button>
                        </div>
                    }
                </div>
                </div>
            </div>)
        }
    }
	
	getValue=(event)=>{
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
	
	
	render() {
        const {sheetData, getPlaceholder, queId, comment, outputText, apiPdfs, kioskResponse, dataMode, needsNote} = this.props;
		//console.log('kioskResponse->',kioskResponse);
		return (
          <div id={queId} className={queId ? 'inner-'+ comment[0] +' childMain col-11 offset-1 '+queId+' collapse' : 'comments '+dataMode} >
            {sheetData.map((comment,i) =>
              <div id={comment[0]} data-pos={comment[10]} key={i}  className={this.classTypes(comment)}>
                {(() => {
					
					let question = comment[4];
					if(outputText){
						question = comment[9] ? comment[9] : comment[4];
					}
					
					let NoteHTML = noteHtml(comment,comment[12]);
					let NoteModal = noteModal(comment,comment[12]);
					let NeedsModal = this.needsModal(comment, comment[0]);
			
					let answer = generatehtmlfromlocation(comment,this.subQuestions,kioskResponse,apiPdfs,outputText);
					let res = kioskResponse.get(comment[0]);
					let that = this;
					switch (comment[5].toLowerCase()) {
                        case 'parent header':
                            return <h3 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'header-m headers-'+comment[0]}> <i className="fa fa-plus-square-o fa-1x mr-2"></i>{ question } </h3>;
                        case 'child header 1':
                            return <div className="child-headers">
                                        <div className="row">
                                            <div className={'col-md-12'}>
                                                <h4 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'header-m headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>
                                                    {question}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                        case 'child header 2':
                            return <div className="child-headers">
                                    <div className="row">
										<div className={'col-md-12'}>
											<h5 data-target={'.'+comment[1].toLowerCase()} id={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'header-m headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>{question}</h5>
										</div>
                                    </div>  
                            </div>;
                        case 'child header 3':
                            return <div className="child-headers">
								<div className="row">
                                    <div className={'col-md-12'}>
                                        <h6 data-target={'.'+comment[1].toLowerCase()} id ={'parent'+comment[1].toLowerCase()}  data-toggle="collapse" onClick={this.toggle.bind(this,comment[1])} className={'header-m headers-'+comment[0]}>  <i className="fa fa-plus-square-o fa-1x mr-2"></i>{question}</h6>
                                    </div>
                                </div>
                            </div>;
						
                        case 'title':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={dataMode == 'TestForm' ? 'col-md-9 input-text' : 'col-md-10 input-text'}>
									<h5>{question}</h5>
								</div>  
							</div>
							 
						</div>)
						
						case 'text':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={dataMode == 'TestForm' ? 'col-md-9 input-text' : 'col-md-10 input-text'}>
									<b>{question}</b>
								</div>  
							</div>
							 
						</div>)
						
						case 'link':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={dataMode == 'TestForm' ? 'col-md-9 input-text' : 'col-md-10 input-text'}>
									{answer}
								</div>
								<div className={'col-md-2'}>
									{NoteHTML} {NoteModal}
								</div>
							</div>
							 
						</div>)
						
						case 'q13' :
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={'col-md-10 answer'}>
									{ answer }
								</div>
								<div className={'col-md-2'}>
									{NoteHTML} {NoteModal}
								</div>
							</div>
							 
						</div>)
						
                        //return <FieldQ13 fieldId={comment[0]} required={comment[15]} buttonLabel={comment[40]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} />
						
						/* case 'launchpad2' :
                        return <Launchpad fieldId={comment[0]} required={comment[15]} buttonLabel={comment[40]} fieldGuid={comment[117]} url={comment[78]} type={comment[138]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} /> */
						
						/* case 'image3' :
						return <FieldInlineImage fieldId={comment[0]} data={comment[99]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} /> */
						
						/* case 'inlinevideo' :
						return <FieldInlineVideo fieldId={comment[0]} data={comment[99]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} /> */
						
						/* case 'pdfapi' :
                        return  <FieldPdfApi fieldId={comment[0]} required={comment[15]} data={apiPdfs} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} func={this.subQuestions} /> */
						
						case 'assessment' :
                        return  <FieldAssessment fieldId={comment[0]} required={comment[15]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} />
						
						case 'subjective' :
                        return  <FieldSubjective fieldId={comment[0]} required={comment[15]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} />
						
						case 'begin':
                            return (<div className="full-width"></div>)
							
						/* case 'calculation':
						case 'wcalculation':
                        return  <FieldCalculation fieldId={comment[0]} question={question} />
						 */
						case 'checklist':
                        return  <Checklist fieldId={comment[0]} required={comment[15]} fieldGuid={comment[117]} question={question} NoteHTML={NoteHTML} NoteModal={NoteModal} outputText={outputText}/>
							
						case 'end':
						return (<div className="full-width"></div>)
						
						case 'calculation':
						case 'wcalculation':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={dataMode == 'TestForm' ? 'col-md-9 input-text' : 'col-md-10 input-text'}>
									{ answer }
								</div>
								<div className={'col-md-2'}>
									{NoteHTML} {NoteModal} {NeedsModal}
								</div>
							</div>
							 
						</div>)
						
						case 'image' :
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={dataMode == 'TestForm' ? 'col-md-9 input-text' : 'col-md-10 input-text'}>
									{ answer }
								</div>
								<div className={'col-md-2'}>
									{NoteHTML} {NoteModal} {NeedsModal}
								</div>
							</div>
							<div className="row">
								<div className={dataMode == 'TestForm' ? 'col-md-10 answer offset-1' : 'col-md-10 answer'}>
									{question}
								</div>   
							</div> 
						</div>)
						
						case 'inlinevideo' :
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={dataMode == 'TestForm' ? 'col-md-9 input-text' : 'col-md-10 input-text'}>
									{ answer }
								</div>
								<div className={'col-md-2'}>
									{NoteHTML} {NoteModal} {NeedsModal}
								</div>
							</div>
							<div className="row">
								<div className={dataMode == 'TestForm' ? 'col-md-10 answer offset-1' : 'col-md-10 answer'}>
									{question}
								</div>   
							</div> 
						</div>)
						
						case 'q5':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={dataMode == 'TestForm' ? 'col-md-9 input-text' : 'col-md-10 input-text'}>
									 { answer } <div className="question-q5">{question}{ comment[15] == 'Y' ? <span className="required">*</span> : ''}</div>
								</div>
								<div className={'col-md-2'}>
									{NoteHTML} {NoteModal} {NeedsModal}
								</div>
							</div>
							 
						</div>)
						
						case 'q10':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={dataMode == 'TestForm' ? 'col-md-9 input-text' : 'col-md-10 input-text'}>
									{this.getQueType(comment[5].toLowerCase(),comment[0],res)} {question} { comment[15] == 'Y' ? <span className="required">*</span> : ''}
								</div>
								<div className={'col-md-2'}>
									{NoteHTML} {NoteModal} {NeedsModal}
								</div>
							</div>
							<div className="row">
								<div className={dataMode == 'TestForm' ? 'col-md-10 answer offset-1' : 'col-md-10 answer'}>
									{ answer }
								</div>   
							</div> 
							 
						</div>)
						
						case 'sig':
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={dataMode == 'TestForm' ? 'col-md-9 input-text' : 'col-md-10 input-text'}>
									{this.getQueType(comment[5].toLowerCase(),comment[0])} {question} { comment[15] == 'Y' ? <span className="required">*</span> : ''}
								</div>
								<div className={'col-md-2'}>
									{NoteHTML} {NoteModal} {NeedsModal}
								</div>
							</div>
							<div className="row">
								<div className={dataMode == 'TestForm' ? 'col-md-10 answer offset-1' : 'col-md-10 answer'}>
									{ answer }
								</div>   
							</div> 
							 
						</div>)
						
						default:
						return (
						<div className="full-width default-div">
							<div className="row">
								{this.renderActions(comment[0],comment[27])}
								<div className={dataMode == 'TestForm' ? 'col-md-9 input-text' : 'col-md-10 input-text'}>
									{question} { comment[15] == 'Y' ? <span className="required">*</span> : ''}
								</div>
								<div className={'col-md-2'}>
									{NoteHTML} {NoteModal} {NeedsModal}
								</div>								
							</div>
							<div className="row">
								<div className={dataMode == 'TestForm' ? 'col-md-10 answer offset-1' : 'col-md-10 answer'}>
									{ answer }
								</div>   
							</div> 
						</div>)                         
                    }
                })()}
                {comment.sub 
                    && 
                    <MainData 
                        sheetData={comment.sub} 
                        queId={comment[1].toLowerCase()}  
                        comment= {comment}
                        outputText= {this.props.outputText}
                        kioskResponse= {kioskResponse}
                        needsNote= {needsNote}
                        checkedNeeds= {this.props.checkedNeeds}
                        handleNeeds= {this.props.handleNeeds}
                        allData= {this.props.allData}
                        apiPdfs= {this.props.apiPdfs}
                        dataMode= {this.props.dataMode}
                        loadDataSet= {this.props.loadDataSet}
                        showResponseData= {this.props.showResponseData}
                        delegateUsers= {this.props.delegateUsers}
                        delegateFilter= {this.props.delegateFilter}
						getResponseValue={this.props.getResponseValue}
						saveNeedsNote={this.props.saveNeedsNote}
						removeNeedsNote = {this.props.removeNeedsNote}
                    />
                }
              </div>
            )}
          </div>
        )
    }
}